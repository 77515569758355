import './fonts.css';
import './style.css';
import axios from 'axios';
import { setUserBearerToken, setUserBearerTokenExpiry, setUserBearerTokenTimeout,getUserBearerToken,getUserModTokenV1,getUserModTokenV2 } from './utils/localManager';
import {
    AuthConfig,
    AuthStrategy,
    DafAuthConfig,
    DafAuthToken,
    DafInMemoryCacheStrategy,
    cacheToken,
    registerSpaComponent,
    start,
    UserType,
    IUser,
    IUserInfo,
    DafAnonymousAuthStrategy
} from '@nab/x-spa-react';
import LoginSpa from './components/LoginSpa/LoginSpa';
import PortalSpa from './components/PortalSpa/PortalSpa';
import GlobalSpa from './components/GlobalSpa/GlobalSpa';
import getContextFromNavManager from './utils/getContextFromNavManager';
import { UserOrg } from '@nab/x-types';
import { Logout } from './components/helper';
import {
    getBearerToken,
    getBearerTokenExpiry,
    getBearerTokenTimeout,
    getIdentityInfo,
    getModTokenV1,
    getModTokenV2,
    setBearerTokenExpiry,
    setBearerTokenTimeout,
    setModTokenV1,
    setModTokenV2,
    setUserInfo
} from './utils/sessionManager';
import { configMgr } from './utils/config/config';
import { defaultHashUserId } from '@nab/nab-x-sdk-browser';
import { DafExchangeStrategy } from '@nab/x-runtime';
import { LaunchDarklyFlagStrategy } from './utils/launchDarkly/LaunchDarklyFlagStrategy';
import { DafGrantType } from '@nab/x-spa';
import { makeRumStrategy } from './utils/appd/rumStrategy';

export interface DafLoginAuthToken<C extends DafAuthConfig = DafAuthConfig> extends DafAuthToken<C> {
    modTokenValueV1?: string;
    modTokenValueV2?: string;
}

class DafLoginAuthStrategy implements AuthStrategy<DafAuthConfig> {
    public supports({ tokenType, tokenSource, isSeed, grantType }: DafAuthConfig): boolean {
        return !isSeed && tokenType === 'Bearer' && tokenSource === 'DAF' && grantType === DafGrantType.UsernamePassword;
    }

    public async authenticate(config: DafAuthConfig): Promise<DafLoginAuthToken<DafAuthConfig>> {
        return {
            tokenValue: getUserBearerToken(),
            modTokenValueV1: getModTokenV1(),
            modTokenValueV2: getModTokenV2(),
            config: config
        };
    }
}

async function spaContextProvider(contextId: string): Promise<any> {
    try {
        if (contextId === 'root-config') {
            return (await axios.get(`config.json`)).data;
        } else {
            return (
                (contextId === 'root2' && (await getContextFromNavManager(contextId))) ||
                (await axios.get(`portal/config/context-${contextId}.json`)).data
            );
        }
    } catch (e) {
        throw new Error(`Failed to retrieve context ${contextId}`);
    }
}

registerSpaComponent('nabtrade-identity', LoginSpa);
registerSpaComponent('ma-jbw-client-portal', PortalSpa);

window.addEventListener('load', async () => {
    try {
        await configMgr?.init().then(async () => {
            const flagStrategy = new LaunchDarklyFlagStrategy(configMgr.get('LAUNCHDARKLY_CLIENT_ID'));

            const identityInfo = JSON.parse(getIdentityInfo());
            let username;
            if (identityInfo && identityInfo.username) {
                username = identityInfo.username;
            }

            const spaShell = await start({
                rootComponent: GlobalSpa,
                rootContextId: 'ma-client-global',
                authConfig: {
                    tokenType: 'Bearer',
                    tokenSource: 'DAF',
                    tenant: 'jbwere',
                    context: 'external',
                    environment: configMgr.get('DAF_ENVIRONMENT'),
                    requestTokenOnLoad: true,
                    scopes: 'idp:user',
                    grantType: DafGrantType.UsernamePassword
                },
                authStrategies: [
                    new DafAnonymousAuthStrategy(configMgr.get('DAF_ENDPOINT_URL'), configMgr.get('SHELL_CLIENT_ID')),
                    new DafLoginAuthStrategy(),
                    new DafExchangeStrategy(configMgr.get('DAF_ENDPOINT_URL'))
                ],
                authTokenCacheStrategy: new DafInMemoryCacheStrategy(),
                spaContextProvider,
                userConfig: {
                    id: username ? username : 'anonymous',
                    idHash: defaultHashUserId(username ? username : 'anonymous'),
                    authenticated: username ? true : false,
                    org: 'NAB' as UserOrg,
                    type: 'customer' as UserType,
                    details: {
                        host: window.location.host
                    }
                },
                servicesConfig: {
                    flags: {
                        strategy: flagStrategy,
                        apiKey: '',
                        initialData: {}
                    },
                    rum: {
                        strategy: makeRumStrategy()
                    }
                },
                hostElement: document.body,
                brand: {
                    id: 'jbwere',
                    themeName: 'jbwere'
                }
            });

            spaShell.config.actions.addEventListener('user_authenticated', async data => {
                const config: AuthConfig = {
                    tokenType: 'Bearer',
                    tokenSource: 'DAF',
                    tenant: 'jbwere',
                    context: 'external',
                    environment: configMgr.get('DAF_ENVIRONMENT'),
                    requestTokenOnLoad: true,
                    scopes: 'idp:user',
                    grantType: DafGrantType.UsernamePassword
                };

                spaShell.config.actions.updateApps({
                    authConfig: config
                } as any);

                await cacheToken({
                    tokenValue: data.bearerToken,
                    config: config
                });

                const user: IUserInfo = {
                    id: data.identityInfo.username,
                    idHash: defaultHashUserId(data.identityInfo.username),
                    org: 'NAB' as UserOrg,
                    type: 'customer' as UserType,
                    authenticated: true,
                    details: {
                        host: window.location.host
                    }
                };
                await flagStrategy.reInitialise(user as IUser);

                //session timeout code
                setUserBearerToken(data.bearerToken);
                setUserBearerTokenTimeout((Date.now() + parseInt(configMgr.get('DAF_IDLE_TIMEOUT_SECS')) * 1000).toString());
                setUserBearerTokenExpiry((Date.now() + parseInt(configMgr.get('DAF_EXPIRY_SECS')) * 1000).toString());


                setUserInfo(data.identityInfo);
                //  setBearerTokenTimeout((Date.now() + parseInt(configMgr.get('DAF_IDLE_TIMEOUT_SECS')) * 1000).toString());
                //  setBearerTokenExpiry((Date.now() + parseInt(configMgr.get('DAF_EXPIRY_SECS')) * 1000).toString());

                await spaShell.config.actions.navigate('/portal');
            });



            spaShell.config.actions.addEventListener('user_timeout', async () => {
                sessionStorage.clear();
                window.history.replaceState({}, document.title, window.location.origin + '/login');
                window.location.replace(window.location.origin + '/login');
            });

            spaShell.config.actions.addEventListener('force_logout', async () => {
                // Logout();
                Logout({
                    revokeDafAuthToken: true
                });
            });

            spaShell.config.actions.addEventListener('force_logout_updated_terms', async () => {
                //   Logout();
                Logout({
                    revokeDafAuthToken: true
                });
            });
        });

        
        // localStorage.removeItem('7662401');
        // localStorage.removeItem('1351430');
    } catch (e) {
        console.error('SPA failed to start', e);
    }
});
