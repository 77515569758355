import React from 'react';
import { SecondaryList, StyledHr, BackToMainMenu, SecondaryListLabel, SecondaryListPanel, MainMenuBottomspace } from './SideMenuNavList.styles';
import { ChevronLeftIcon } from '@nab/nui-react';
import { SideMenuSecondaryListItem } from './SideMenuSecondaryListItem';
import { getIsClient } from '../../../utils/sessionManager';

interface SubMenuList {
    subMenuOpen: any,
    isTablet: any,
    item: any,
    subRoute: any,
    baseRoute: any,
    actions: any,
    currentActiveIndex: any,
    setNavItemIndex: any,
    setIsCollapsed: any,
}

export function SideMenuSecondaryMenu({ subMenuOpen, isTablet, item, subRoute, baseRoute, actions,
                                    setNavItemIndex, setIsCollapsed}: SubMenuList) {

    const filteredMenuitem = item?.children.filter(i => {
        return i.label != 'Client list';
    });

    const AdvisorfilteredMenuitem = item?.children.filter(i => {
        return i.label != 'Profile';
    });

    const isClient = getIsClient();
                              
    return (
        <SecondaryList data-testid="secondarylist" subMenuOpen={subMenuOpen} isTablet={isTablet} isCollapsed={false}>
            <SecondaryListPanel data-testid="secondaryListPanel" subMenuOpen={subMenuOpen} isTablet={isTablet}>
                {!isTablet && (
                    <>
                        <BackToMainMenu data-testid="backToMainMenu" onClick={() => setNavItemIndex(-1)}>
                            <ChevronLeftIcon />
                            <MainMenuBottomspace>{'Main menu'}</MainMenuBottomspace>
                        </BackToMainMenu>
                        <StyledHr data-testid="styledHr" />
                    </>
                )}
                <SecondaryListLabel data-testid="secondaryListLabel">{item?.label}</SecondaryListLabel>
                {isClient === 'true' && filteredMenuitem !== undefined
                    ? filteredMenuitem?.map((i, index) => (
                          <SideMenuSecondaryListItem
                              key={i.route}
                              header={item.label}
                              route={i.route}
                              index={index}
                              subRoute={subRoute}
                              baseRoute={baseRoute}
                              label={i.label}
                              icon={i.icon}
                              actions={actions}
                              setIsCollapsed={setIsCollapsed}
                          />
                      ))
                    : AdvisorfilteredMenuitem?.map((i, index) => (
                          <SideMenuSecondaryListItem
                              key={i.route}
                              header={item.label}
                              route={i.route}
                              index={index}
                              subRoute={subRoute}
                              baseRoute={baseRoute}
                              label={i.label}
                              icon={i.icon}
                              actions={actions}
                              setIsCollapsed={setIsCollapsed}
                          />
                      ))}
            </SecondaryListPanel>
        </SecondaryList>
    );
}

export default SideMenuSecondaryMenu;
