import styled from "styled-components";
import {Row} from '@nab/nui-react';

type TMenuBarProps = {
    readonly isCollapsed?: boolean;
    readonly isTablet?: boolean;
    readonly subMenuOpen?: boolean;
};

const headerFullHeight = '100%';
const headerCollapsedHeight = '56px';

export const SidebarContainer = styled.div<TMenuBarProps>`
    display: block;
    height: ${headerCollapsedHeight};
`;
export const Sidebar = styled.div<TMenuBarProps>`
    display: flex;
    flex-direction: column;
    transition: ${({ isCollapsed }: any) => (isCollapsed ? 'height ease 0ms 150ms' : 'none')};
    position: absolute;
    z-index: 100;
    width: 100%;
    height: ${({ isCollapsed }: any) => (isCollapsed ? headerCollapsedHeight : headerFullHeight)};
`;

export const MenuHeader = styled.div<TMenuBarProps>`
    display: block;
    width: 100%;
    box-shadow: #c9c3c3 0px -1px 2px 1px;
    z-index: 100;
    background-color: ${({theme}) => theme.color.primary};
`;

export const HamburgerLogoWrap = styled.div<TMenuBarProps>`
    display: flex;
    height: ${headerCollapsedHeight};
    align-content: center;
    align-items: center;
`;


export const MenuBody = styled.div<TMenuBarProps>`
    flex: 1;
    overflow-x: auto;
    transition: opacity ease 250ms;
    position: relative;
    opacity: ${({ isCollapsed }: any) => (isCollapsed ? 0 : 1)};
`;

export const MenuBodyContent = styled.div<TMenuBarProps>`
    display: flex;
    transition: left ease 150ms;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: ${(props) => !props.isTablet ? 'absolute': 'relative'};
    left: ${(props) => props.isCollapsed ? '-100%': '0%'} ;
`;

export const MenuBodyContentLists = styled.div<TMenuBarProps>`
    display: flex;
    height: 100%;
    width: 100%;
`;

export const MenuBodyBackgroundOverlay = styled.div<TMenuBarProps>`
    position: relative;
    margin: auto;
    z-index: 100;
    transition: ${({ isCollapsed }: any) => isCollapsed ? 'opacity ease 100ms': 'opacity ease 300ms 250ms'};
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: ${({ isCollapsed }: any) => (isCollapsed ? 0 : .7)};
`;

export const SideMenuFooter = styled.div<TMenuBarProps>`
    display: flex;
    width: 100%;
    position: relative;
`;

export const TickerAndSearch = styled.div<TMenuBarProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${(props) => !props.isTablet ? '1.5rem': '1.5rem 2rem'};
    gap: 0.25rem;
    background-color: ${({theme}) => theme.color.white};
`;

export const LoginDetailsWrap = styled.div`
    width: 100%;
    border-top: ${({theme}) => theme.color.grey90} 1px solid;
    padding: 1rem 0;
`;

export const HamburgerIconWrapper = styled.div<TMenuBarProps>`
    padding-left: ${(props) => !props.isTablet ? '.75rem': '1.25rem'};
    svg {
        path: {
            color: ${({theme}) => theme.color.white}
            fill: ${({theme}) => theme.color.white}
        }
    };
`;

export const LogoWrap = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-left: 0.25rem;
        max-height: 48px;
        min-height: 25px;
        height: auto;
    }
`;

export const TabletButtonWrap = styled.div`
    display: flex;
    height: 58px;
    padding-right: 2rem;
    gap: 1rem;
    align-items: center;
    justify-content: end;
`;


export const StyledSquareButtonRow = styled(Row)`
    gap: 0.5rem;
    @media (max-width: 575px){
        margin-right: 12px !important;
    }
    `
;
export const SquareButton = styled.div<{isPrimary?: boolean}>`
    padding: 0.5rem;
    width: 56px;
    height: 56px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: ${ (props) => (props.isPrimary ? ({ theme }) => theme.color.primary : ({ theme }) => theme.color.white)};
    color: ${ (props) => (props.isPrimary ? ({ theme }) => theme.color.white : ({ theme }) => theme.color.primary)};
    img {
        margin: 0;
        height: 20px;
    }
    svg {
        path {
            fill: ${ (props) => (props.isPrimary ? ({ theme }) => theme.color.white : ({ theme }) => theme.color.primary)};
            color: ${ (props) => (props.isPrimary ? ({ theme }) => theme.color.white : ({ theme }) => theme.color.primary)};
        }
    }
`;