import React, { useEffect, useState, useRef } from 'react';
import { SpaWrapper, PortalHeaderWrap, MainLayout, MainContainer, FooterContainer } from './MainSpa.styles';
import Header from '../Header/Header';
import SubHeaderNav from '../Header/SubHeaderNav/SubHeaderNav';
import { AppError, AppLoading } from '../common';
import { AppViewport } from '../AppViewport/AppViewport';
import { MiniAppInstance } from '@nab/x-spa-react';
import { useMediaQuery } from '@nab/nui-react';
import ReturnToTop from '../ReturnToTop/ReturnToTop';
import { useAppConfig } from '@nab/nab-x-react';
import SideMenuNav from '../Header/SideMenuNav/SideMenuNav';
import Footer from '../Footer/Footer';

interface MainSpaProps {
    activeAppInstance: any;
    actions: any;
    activeRoute: any;
    subRoute: any;
    items: any;
    baseRoute: any;
    navigate: any;
}

export default function MainSpa({ activeAppInstance, actions, activeRoute, subRoute, items, baseRoute, navigate }: MainSpaProps) {
    const isSmallScreen = useMediaQuery({ maxWidth: 'sm' });
    const [showFooter, setShowFooter] = useState(false);
    const loading = false;

    const error = false;
    const userLoginDetails = {
        profileName: '',
        lastLogin: Date.now()
    };

    const changePasswordRef = useRef(false);

    useEffect(() => {
        let changePassword = document.getElementsByTagName('nab-idp-change-password');
        if (changePassword.length > 0) changePasswordRef.current = true;

        setTimeout(() => {
            setShowFooter(true);
        }, 1000);
        return () => {
            setShowFooter(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href]);

    const isNewWindow = window.location.pathname === '/portal/insights/details';

    const belowDesktop = useMediaQuery('(max-width: 1139px)');
    const isDesktop = useMediaQuery('(min-width: 1140px)');
    const isTablet = useMediaQuery('(min-width: 576px) and (max-width: 1139px)');

    return (
        <>
            <SpaWrapper>
                <PortalHeaderWrap className="portalHeader" newWindow={isNewWindow}>
                    {belowDesktop && (
                        <SideMenuNav
                            activeRoute={activeRoute}
                            subRoute={subRoute}
                            baseRoute={baseRoute}
                            navigate={navigate}
                            loading={loading}
                            error={error}
                            userLoginDetails={userLoginDetails}
                            items={items}
                            activeAppInstance={activeAppInstance}
                            appConfig={useAppConfig}
                            actions={actions}
                            isTablet={isTablet}
                        />
                    )}

                    {isDesktop && (
                        <>
                            <Header actions={actions} loading={loading} error={error} userLoginDetails={userLoginDetails} />
                            <SubHeaderNav
                                activeRoute={activeRoute}
                                subRoute={subRoute}
                                items={items}
                                baseRoute={baseRoute}
                                navigate={navigate}
                                actions={actions}
                                loading={loading}
                                error={error}
                                userLoginDetails={userLoginDetails}
                            />
                        </>
                    )}
                </PortalHeaderWrap>
                <AppViewport>
                    <MainContainer className={changePasswordRef.current ? 'change-password' : ''}>
                        <MainLayout belowDesktop={belowDesktop}>
                            {!loading && <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />}
                        </MainLayout>
                    </MainContainer>
                </AppViewport>
                {isSmallScreen && <ReturnToTop />}
                {!isNewWindow && (
                    <FooterContainer newWindow={isNewWindow}>
                        <Footer />
                    </FooterContainer>
                )}
            </SpaWrapper>
        </>
    );
}
