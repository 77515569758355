import React from 'react';
import { Button, BackIcon, Row, Col } from '@nab/nui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
    svg {
        transform: rotate(90deg);
    }
`;

const ReturnToTop = () => {
    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    return (
        <Row  data-testid="row"bottomSpacing="xxlg">
            <Col data-testid="row"  xs="12">
                <Wrapper>
                    <Button variant="alternate" styledAsLink label="Return to top" onClick={handleClick} icon={BackIcon} />
                </Wrapper>
            </Col>
        </Row>
    );
};

export default ReturnToTop;
