import React, { useEffect, useState } from 'react';
import { Container, LockClosedIcon, Button } from '@nab/nui-react';
import { isDafUserSessionGood } from '../../utils/tokens/tokens';
import { Logout, RevokeDafAuthToken } from '../helper';
import { getArbirtaryKeyFromLS } from '../../utils/localManager';
import { SessionTimeOutModal } from './SessionMgtTimer.styles';

export function SessionMgtTimer({ idleTimerRevokeStatus }) {
    const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);

    const handleSessionExpired = async () => {
        setIsSessionExpired(false);
        await Logout({
            revokeDafAuthToken: false
        });
    };

    if (idleTimerRevokeStatus) RevokeDafAuthToken();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isDafUserSessionGood()) {
                RevokeDafAuthToken();
                setIsSessionExpired(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            {(isSessionExpired || idleTimerRevokeStatus) && (
                <SessionTimeOutModal
                    id="session-expired-modal"
                    title={'Session expired'}
                    icon={LockClosedIcon}
                    alignment="left"
                    hasClose={true}
                    hasEscapeClose={true}
                    hasBackdropClose={true}
                    onClose={handleSessionExpired}
                >
                    <Container>
                        <p>Your session has now expired, please log in again</p>
                        <Button variant="primary" onClick={handleSessionExpired} label={'Back to login'} />
                    </Container>
                </SessionTimeOutModal>
            )}
        </>
    );
}

export default SessionMgtTimer;
