import React from 'react';
import { MiniAppInstance, SpaProps, useFlag } from '@nab/x-spa-react';
import { SpaWrapper } from './GlobalSpa.styles';
import AppViewport from '../AppViewport/AppViewport';
import RouteError from '../Error/RouteError';
import AppInitError from '../Error/AppInitError';
import AccessDenied from '../Error/AccessDenied';
import { GlobalStyle, Theme } from '@nab/nui-react';

/**
 * you can pass the following additional parameters in the GlobalSpa:
 * context, actions, ...others
 */
export function GlobalSpa({ activeAppInstance, initError, routeError }: SpaProps) {
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme theme="jbwere">
                <SpaWrapper>
                    <AppViewport>
                        <>
                                {initError ? <AppInitError app={activeAppInstance && activeAppInstance.app} error={initError} /> : ''}
                                {!initError && routeError ? <RouteError routeError={routeError} /> : ''}
                                {!initError && !routeError && activeAppInstance ? (
                                    <MiniAppInstance errorSlot={AppInitError} appInstance={activeAppInstance} />
                                ) : (
                                    ''
                                )}
                        </>
                    </AppViewport>
                </SpaWrapper>
            </Theme>
        </>
    );
}

export default GlobalSpa;
