import { Accordion, AccordionItem, Button } from '@nab/nui-react';
import React from 'react';
import { Logout } from '../helper';
import { TACContainer, TACH3, TACMainHeading, TACContent, TACMainContainer, AcceptBtn, TACContainerWithBtn, TACWrapper } from './styles';
import { getShellActions } from '@nab/x-runtime';
import { getUserGroup, setIsClient, getIdentityInfo } from '../../utils/sessionManager';
export function TermsAndConditions() {
    const shadowRootElement =
        document.querySelector('ma-jbw-client-portfolio') === null
            ? document.querySelector('#root') || document.querySelector('body')
            : document.querySelector('ma-jbw-client-portfolio').shadowRoot;
    const handleAccept = () => {
        const identityInfo = JSON.parse(getIdentityInfo());
        if (localStorage.getItem(identityInfo?.username) == null) localStorage.setItem(identityInfo?.username, 'true');
        if (getUserGroup() !== 'STAFF' && getUserGroup() !== 'INT') {
            setIsClient(true);
            getShellActions().navigate('/portal/portfolio/portfolio-summary');
        } else {
            setIsClient(false);
            getShellActions().navigate('/portal/profile-help/client-list');
        }
    };
    return (
        <TACWrapper>
            <TACMainContainer
                id={'TAC'}
                title=""
                alignment={'middle'}
                onClose={() => Logout({
                    revokeDafAuthToken: true
                })}
                hasClose
                hasBackdropClose={false}
                hasEscapeClose
                rootNode={shadowRootElement}
            >
                <TACContainerWithBtn>
                    <TACContainer>
                        <>
                            <TACMainHeading>Terms and conditions</TACMainHeading>
                            <TACH3>JBWere Client Portal – Data Providers Terms and Conditions</TACH3>
                            <TACContent>
                                This Agreement is dated on the date that it is first accepted by the End User by clicking the Accept button on this
                                page.
                                <br />
                                <span>Key terms of our data agreements can be provided to you upon request.</span>
                            </TACContent>
                            <TACContent>
                                Between the parties: JBWere Limited ABN 68 137 978 360, AFSL 341162 of Level 31, 405 Bourke Street Melbourne VIC 3000
                                (JBWere, we, us or our) and the End User: The person accepting this agreement or if that person is acting in the
                                capacity of agent, the principal of the person accepts the agreement (End User or you).
                            </TACContent>
                            <br />
                            <Accordion multipleExpanded headingLevel={3} aria-labelledby="mylabel">
                                <AccordionItem label="1 Definitions" isOpen>
                                    <TACContent>
                                        The meanings of the terms used in this Agreement are set out in this clause. Any capitalised term not defined
                                        in this clause has the meaning given in the JBWere Multi-Asset Platform Core Terms and Conditions.
                                        <br />
                                        Term and Meaning
                                        <br />
                                        Access ID: means personal identification which enables a particular User to access Information received from a
                                        particular Information Supplier and which simultaneously represents a Unit of Count. An appropriate
                                        application procedure (e.g. registration by user name and password) ensures that only the registered
                                        individual can use the Access ID.
                                        <br />
                                        Agreement: means this agreement, as amended in accordance with its provisions from time to time.
                                        <br />
                                        ASX: means the financial market known as the Australian Securities Exchange, a financial market operated by
                                        ASX Limited ABN 98 008 624 691.
                                        <br />
                                        ASX Information means:
                                        <br />
                                        (a) ‘Information’ as that term is defined in the Reference Point Arrangement;
                                        <br />
                                        (b) ‘Information’ as that term is defined in the MarketSource Arrangement; and
                                        <br />
                                        (c) Real-time Information.
                                        <br />
                                        Broker Specific Information has the meaning given in the MarketSource Arrangement.
                                        <br />
                                        Business Day: means a day on which banks are open for business in Victoria excluding a Saturday, Sunday or
                                        public holiday in that city.
                                        <br />
                                        CBOE Data: means ‘CBOE Data’, as that term is defined in the CDS Arrangement.
                                        <br />
                                        CDS: means Cboe Data Services, LLC, a Delaware limited liability company with its principal place of business
                                        at 400 South LaSalle Street, Chicago, Illinois 60605.
                                        <br />
                                        CDS Arrangement: means the agreement relating to the CDS Information entitled Customer Agreement – Index Data,
                                        dated December 2018, between us and CDS, key terms of which we will provide to you on request.
                                        <br />
                                        CDS Information: means ‘Data’, including ‘CBOE Data’, as those terms are defined in the CDS Arrangement.
                                        <br />
                                        DBA: means Deutsche Börse AG, a company with its principal place of business at 60485 Frankfurt am Main,
                                        Germany.
                                        <br />
                                        DBA Arrangement: means the agreement relating to the DBA Information entitled General Terms and Conditions to
                                        the Market Data Dissemination Agreement of Deutsche Börse AG, dated 1 January 2020, between us and DBA, key
                                        terms of which we will provide to you on request.
                                        <br />
                                        DBA Information: means ‘Information’ and ‘Real-time Information’, as those terms are defined in the DBA
                                        Arrangement.
                                        <br />
                                        Entitlement System: means electronic systems via which Access IDs are entitled and which further continuously
                                        controls for each Access ID and each device the actual entitlement to Information and which provides complete
                                        records.
                                        <br />
                                        Government Agency: any government or governmental, administrative, monetary, fiscal or judicial body,
                                        department, commission, authority, tribunal, agency or entity in any part of the world.
                                        <br />
                                        Guide: means the document titled ASX Market Information Products & Services Guide published by the ASX, a copy
                                        of which may be accessed at www.asxonline.com.
                                        <br />
                                        Honesty Statement: has the meaning outlined in clause 4.9(a) of this Agreement.
                                        <br />
                                        Index: means ‘Index’, as the term is defined in the NZX Arrangement.
                                        <br />
                                        Information: means any or all of the following (as the context requires):
                                        <br />
                                        (a) to the extent that you have a right to receive information provided by CDS through your Account, CDS
                                        Information.
                                        <br />
                                        (b) to the extent that you have a right to receive information provided by ASX through your Account, ASX
                                        Information;
                                        <br />
                                        (c) to the extent that you have a right to receive information provided by DBA through your Account, DBA
                                        Information;
                                        <br />
                                        (d) to the extent that you have a right to receive information provided by NZX through your Account, NZX
                                        Information;
                                        <br />
                                        (e) to the extent that you have a right to receive information provided by Morningstar through your Account,
                                        Morningstar Information;
                                        <br />
                                        (f) to the extent that you have a right to receive information provided by Nikkei through your Account, Nikkei
                                        Information; and
                                        <br />
                                        (g) to the extent that you have a right to receive information provided by S&P through your Account, S&P
                                        Information.
                                        <br />
                                        JBWere Multi-Asset Platform Agreement: The agreement between you and JBWere on the terms and conditions set
                                        out in Part A and Part D of the JBWere Multi-Asset Platform Core Terms and Conditions.
                                        <br />
                                        JBWere Multi-Asset Platform Core Terms and Conditions: means the JBWere Multi-Asset Platform Core Terms and
                                        Conditions issued by JBWere and dated 6 November 2023, as amended in accordance with [clause 24] of Division 1
                                        of Part A of the JBWere Multi-Asset Platform Agreement from time to time.
                                        <br />
                                        Limited Extracts has the meaning outlined in clause 4.1(f) of this Agreement.
                                        <br />
                                        MarketSource Arrangement: means the document entitled MarketSource General Terms and Conditions, dated April
                                        2011, between us and ASX, key terms of which we will provide to you on request.
                                        <br />
                                        Morningstar: means Morningstar Australasia Pty Ltd ABN 95 090 665 544, a company with its principal place of
                                        business at Level 3, International Tower 1, 100 Barangaroo Avenue, Barangaroo NSW 2000.
                                        <br />
                                        Morningstar Arrangement: means the agreement relating to the Morningstar Information entitled Seventh
                                        Amendment to Morningstar Australasia Licensing Agreement, dated 3 April 2020, between us and Morningstar, key
                                        terms of which we will provide to you on request.
                                        <br />
                                        Morningstar Information: means any information provided by Morningstar (if any) under the Morningstar
                                        Arrangement.
                                        <br />
                                        New Original Works: means any work that is created partly or wholly from Information, but does not display any
                                        Information and cannot be used to recalculate or recreate Information.
                                        <br />
                                        Nikkei: means Nikkei Inc, a company with its principal place of business at [1325 Avenue of the Americas,
                                        Suite 2500 New York, NY 10019]
                                        <br />
                                        Nikkei Arrangement: means the agreement relating to the Nikkei Information entitled Confirmation Letter for
                                        Usage of Nikkei Indices, dated 8 January 2020, between us and Nikkei, in the form annexed to this Agreement or
                                        provided to you on request.
                                        <br />
                                        Nikkei Information: means ‘Nikkei Indices’, as that term is defined in the Nikkei Arrangement.
                                        <br />
                                        Non-Display Application means ‘Non-Display Application’, as the term is defined in the NZX Arrangement.
                                        <br />
                                        NZX: means NZX Limited, company number 1266120, a company with its principal place of business at Level 1, 11
                                        Cable Street, Wellington, New Zealand.
                                        <br />
                                        NZX Arrangement: means the agreement relating to NZX Information entitled Information Licence Agreement (ILA
                                        Cover Agreement), dated 1 October 2019, between us and NZX, key terms of which we will provide to you on
                                        request.
                                        <br />
                                        NZX Information: means ‘Information’ and ‘Real-time Information’, as those terms are defined in the NZX
                                        Arrangement.
                                        <br />
                                        Operational Controls: means your systems, rules, procedures, authorisations and disciplines which, taken
                                        together and to the satisfaction of the relevant Supplier:
                                        <br />
                                        (a) record and identify all authorised access to Information, where applicable by means of Units of Count;
                                        <br />
                                        (b) prevent or restrict unauthorised access to or dissemination of Information; and
                                        <br />
                                        (c) identify and record unauthorised access and facilitate appropriate action.
                                        <br />
                                        Private Use Subscriber: means a natural person receiving information for the purpose of managing their own
                                        personal investments and not for any business purpose, nor for the purpose of giving any form of advice to any
                                        other person.
                                        <br />
                                        Real-time Information: means
                                        <br />
                                        (a) in the case of NZX Information, all Information from the time of original dissemination to the time twenty
                                        (20) minutes after such dissemination.
                                        <br />
                                        (b) in the case ASX Information on ASX venues excluding ASX 24, all Information from the time of original
                                        dissemination to the time twenty (20) minutes after such dissemination, excluding Restricted Information and
                                        Broker Specific Information;
                                        <br />
                                        (c) in the case ASX Information in relation to ASX 24, all Information from the time of original dissemination
                                        to the time ten (10) minutes after such dissemination, excluding Restricted Information and Broker Specific
                                        Information; and
                                        <br />
                                        (d) in the case of DBA Information, all Information from the time of original dissemination to the time
                                        fifteen (15) minutes after such dissemination.
                                        <br />
                                        Restricted Information: means ‘Restricted Information’, as that term is defined in the MarketSource
                                        Arrangement.
                                        <br />
                                        Reference Point Arrangement: means the document entitled ReferencePoint General Terms and Conditions, version
                                        1.1, between us and ASX, key terms of which we will provide to you on request.
                                        <br />
                                        S&P: means S&P Dow Jones Indices LLC, a Delaware limited liability company its principal place of business at
                                        55 Water Street, 44th Floor, New York, NY 10041.
                                        <br />
                                        S&P Arrangement: means the agreement relating to S&P Information entitled Agreement for Web Display of Index
                                        Values, dated as effective from 1 June 2020, between us and S&P, key terms of which we will provide to you on
                                        request.
                                        <br />
                                        S&P Information: means ‘Index Values’, as the term is defined in the S&P Arrangement.
                                        <br />
                                        Supplier: means the entity that has supplied the information to us for onward dissemination to you, which will
                                        be any one or more of:
                                        <br />
                                        (a) S&P;
                                        <br />
                                        (b) DBA;
                                        <br />
                                        (c) NZX;
                                        <br />
                                        (d) ASX;
                                        <br />
                                        (e) CDS;
                                        <br />
                                        (f) Nikkei; or
                                        <br />
                                        (g) Morningstar.
                                        <br />
                                        Units of Count: means:
                                        <br />
                                        (a) in the case of ASX Information, the units of count approved by ASX and set out in the Guide; and
                                        <br />
                                        (b) in the case of DBA Information, the units of count approved by DBA and contained in paragraph 12.3 of the
                                        DBA Arrangement.
                                        <br />
                                        User means any person to whom you provide access to the Information through your Account.
                                        <br />
                                        <b>1.2 Interpretation</b>
                                        <br />
                                        In this Agreement:
                                        <br />
                                        (a) Headings and bold type are for convenience only and do not affect the interpretation of this Agreement.
                                        <br />
                                        (b) The singular includes the plural and the plural includes the singular.
                                        <br />
                                        (c) Words of any gender include all genders.
                                        <br />
                                        (d) Other parts of speech and grammatical forms of a word or phrase defined in this Agreement have a
                                        corresponding meaning.
                                        <br />
                                        (e) An expression importing a person includes any company, partnership, joint venture, association,
                                        corporation or other body corporate and any Government Agency as well as an individual.
                                        <br />
                                        (f) A reference to a clause, party, schedule, attachment or exhibit is a reference to a clause of, and a
                                        party, schedule, attachment or exhibit to, this Agreement.
                                        <br />
                                        (g) A reference to any legislation includes all delegated legislation made under it and amendments,
                                        consolidations, replacements or re‑enactments of any of them.
                                        <br />
                                        (h) A reference to a document includes all amendments or supplements to, or replacements or novations of, that
                                        document.
                                        <br />
                                        (i) A reference to a party to a document includes that party’s successors and permitted assignees.
                                        <br />
                                        (j) A promise on the part of 2 or more persons binds them jointly and severally.
                                        <br />
                                        (k) A reference to an agreement other than this Agreement includes a deed and any legally enforceable
                                        undertaking, agreement, arrangement or understanding, whether or not in writing.
                                        <br />
                                        (l) A reference to liquidation or insolvency includes appointment of an administrator, compromise,
                                        arrangement, merger, amalgamation, reconstruction, winding up, dissolution, deregistration, assignment for the
                                        benefit of creditors, scheme, composition or arrangement with creditors, insolvency, bankruptcy, or any
                                        similar procedure or, where applicable, changes in the constitution of any partnership or person, or death.
                                        <br />
                                        (m) No provision of this Agreement will be construed adversely to a party because that party was responsible
                                        for the preparation of this agreement or that provision.
                                        <br />
                                        (n) A reference to a body, other than a party to this Agreement (including an institute, association or
                                        authority), whether statutory or not:
                                        <br />
                                        (1) which ceases to exist; or
                                        <br />
                                        (2) whose powers or functions are transferred to another body,
                                        <br />
                                        is a reference to the body which replaces it or which substantially succeeds to its powers or functions.
                                        <br />
                                        <b>1.3 Interpretation of inclusive expressions</b>
                                        <br />
                                        Specifying anything in this Agreement after the words ‘include’ or ‘for example’ or similar expressions does
                                        not limit what else is included.
                                        <br />
                                        <b>1.4 Business Day</b>
                                        <br />
                                        Where the day on or by which any thing is to be done is not a Business Day, that thing must be done on or by
                                        the next Business Day.
                                        <br />
                                        <b>1.5 Inconsistency</b>
                                        <br />
                                        If there are any inconsistencies between this Agreement and the JBWere Multi-Asset Platform Agreement, this
                                        Agreement will prevail to the extent of the inconsistency.
                                    </TACContent>
                                </AccordionItem>
                                <br />
                                <AccordionItem label="2 Subscription" isOpen>
                                    <TACContent>
                                        By clicking Accept, you:
                                        <br />
                                        (a) represent that you have an Account in the JBWere Multi-Asset Platform, a term of which includes a right to
                                        access the Information;
                                        <br />
                                        (b) acknowledge and agree that:
                                        <br />
                                        (1) you are subscribing to access the Information through your Account on the terms and conditions in this
                                        Agreement;
                                        <br />
                                        (2) this Agreement is an Additional Service Agreement;
                                        <br />
                                        (3) the provision of Information is an Additional Service provided through the JBWere Multi-Asset Platform and
                                        should be read in conjunction with, and supplements the JBWere Multi-Asset Platform Core Terms and Conditions;
                                        <br />
                                        (4) you have read, and agree to, all of the terms and conditions in this Agreement;
                                    </TACContent>
                                </AccordionItem>
                                <br />
                                <AccordionItem label="3 Consideration" isOpen>
                                    <TACContent>
                                        This Agreement is entered into in consideration of the parties incurring obligations and giving rights under
                                        this document and for other valuable consideration.
                                    </TACContent>
                                </AccordionItem>
                                <br />
                                <AccordionItem label="4 Terms and conditions of subscription" isOpen>
                                    <TACContent>
                                        <span> 4.1 Use and distribution of Information</span>
                                        <br />
                                        (a) You will use the Information internally only (that is, for yourself, your affiliates, and/or your
                                        employees). Subject to clause 4.1(f) below, you will not share the Information with any third party, by any
                                        means, except to the extent that either:
                                        <br />
                                        (1) the Supplier has given you express written approval to do this; or
                                        <br />
                                        (2) in the case of CDS Information, the third party has entered into a separate licence agreement with CDS,
                                        or, with respect to any CDS Information owned by a third party, the applicable provider of such CDS
                                        Information.
                                        <br />
                                        (b) You will not do any act or thing which aids, counsels another to do, consents to or procures the doing of
                                        anything prohibited in clause 4.1(a) above.
                                        <br />
                                        (c) For the purposes of clause 4.1(a) above, to ‘share’ the Information to a third party includes to store,
                                        download, enable access to, reproduce, display, distribute or redistribute, disseminate, broadcast, publish,
                                        transmit, or provide access to that party. You will not share any confidential Information.
                                        <br />
                                        (d) If you have a right to access NZX Information through your Account as a Private Use Subscriber, you may
                                        use the Information only for your own personal use relating to the management of your own personal investments
                                        and not for any business purpose, nor for the purpose of giving any form of advice to any other person.
                                        <br />
                                        (e) In relation to any information you receive under clause 4.1(d) above, you may not:
                                        <br />
                                        (1) create New Original Works based on an Index;
                                        <br />
                                        (2) use Information to create any Index, create reference prices for any financial product or license, without
                                        the prior written approval from NZX or the relevant provider of information that has licensed NZX to make its
                                        information available; or
                                        <br />
                                        (3) use Real-time Information in Non-Display Applications, or to create any Index, or as the reference price
                                        for any financial product, without the prior written permission of NZX;
                                        <br />
                                        (f) In the case of ASX Information or NZX Information, you may include limited extracts of Information on an
                                        ad-hoc basis and no more than occasionally, in written or oral communications with third parties, without fees
                                        or other obligations, provided that:
                                        <br />
                                        (1) these communications do not involve the regular or systematic distribution, display or processing of
                                        Information;
                                        <br />
                                        (2) it does not involve the provision of Real-time Information or any other form of continuously updating or
                                        streaming Information; and
                                        <br />
                                        (3) it is attributed to the relevant Supplier.
                                        <br />
                                        For the purposes of this clause, you acknowledge that we may reasonably act on the opinion or advice of the
                                        relevant Supplier to:
                                        <br />
                                        (1) determine whether communications including Information qualify as limited extracts for this purpose
                                        (Limited Extracts);
                                        <br />
                                        (2) determine whether communications involve ‘systematic’ distribution, display or processing;
                                        <br />
                                        (3) limit or withdraw rights to distribute Limited Extracts of Information; and
                                        <br />
                                        (4) regard as distribution of Information any communication of Information that does not qualify as Limited
                                        Extracts.
                                        <br />
                                        (g) You must not use the Information for an illegal purpose.
                                        <br />
                                        <span>4.2 Attribution</span>
                                        <br />
                                        If you have a right to access DBA Information through your Account, you will ensure that your use of the
                                        Information shall be in a manner which ensures that, where relevant:
                                        <br />
                                        (a) in the case of DBA Information from DBA, you will name DBA as the source of the Information where
                                        Information is displayed (at least “Source: Deutsche Börse AG”); or
                                        <br />
                                        (b) in the case of DBA Information from a provider that has licensed DBA to make the Information available,
                                        you comply with any specific requirements as to form and content of the reference to the source, as specified
                                        by that provider.
                                        <br />
                                        <span>4.3 User identifiers, IDs, and passwords</span>
                                        <br />
                                        If you have a right to access ASX Information and DBA Information through your Account:
                                        <br />
                                        (a) you will ensure that each User is issued with a unique user identifier and password, or other security
                                        device fit for the purpose as part of an approved auditable security systems and controls that allow access
                                        to, record and report all usage of Information by reference to the applicable Units of Count under this
                                        Agreement; and
                                        <br />
                                        (b) you will ensure that these unique users identifiers and passwords are not shared with other Users or any
                                        unauthorised person.
                                        <br />
                                        <span>4.4 ASX Information</span>
                                        <br />
                                        If you have a right to access ASX Information through your Account, to the extent permitted by law you agree
                                        that you must not do anything that JBWere is prohibited from doing under the MarketSource Arrangement or
                                        Reference Point Arrangement.
                                        <br />
                                        <span> 4.5 NZX Information</span>
                                        <br />
                                        If you have a right to access NZX Information through your Account, you agree that:
                                        <br />
                                        (a) you will comply with the conditions of use of Information by subscribers as specified in the NZX
                                        Arrangement, as published by NZX from time to time or as otherwise notified to you by us from time to time;
                                        and
                                        <br />
                                        (b) the NZX Arrangement is directly enforceable by NZX against you.
                                        <br />
                                        <span>4.6 Nikkei Information</span>
                                        <br />
                                        If you have access to the Nikkei Information through your Account, you acknowledge that Nikkei does not
                                        warrant the accuracy and completeness of the Nikkei Information.
                                        <br />
                                        <span></span>
                                        <b>4.7 CDS Information</b>
                                        <br />
                                        If you have a right to receive CDS Information through your account, you acknowledge:
                                        <br />
                                        (a) the absence of any guarantee with respect to the CDS Information; and
                                        <br />
                                        (b) that CDS provides the CDS Information “as is” without warranty of any kind, either express or implied,
                                        including without limitation, any warranty with respect to accuracy, completeness, timeliness,
                                        merchantability, or fitness for a particular purpose. Neither CDS nor any provider of data to CDS, nor any of
                                        their respective affiliates, nor their respective directors, officers, employees, contractors, and agents
                                        shall have any liability of any kind (including, but not limited to, for any direct, indirect, incidental,
                                        special, consequential, or punitive damages or any damages for lost profits or lost opportunities and whether
                                        based upon contract, tort, warranty, or otherwise) for any inaccuracies, omissions, human or machine errors,
                                        or other irregularities in the data or for any cessation, discontinuance, failure, delay, malfunction,
                                        suspension, interruption, or termination of, or with respect to, the provision of the data.
                                        <br />
                                        <span>4.8 Record-keeping and audit</span>
                                        <br />
                                        (a) You will keep complete, accurate and up-to-date books and records relating to the Information, for at
                                        least five (5) years from the creation of the record, including records which:
                                        <br />
                                        (1) concern the operation of your Entitlement System;
                                        <br />
                                        (2) as notified by us are important to us for our own invoicing, record-keeping and payment obligations to any
                                        Supplier;
                                        <br />
                                        (3) as notified by us are necessary or important for us to comply with our contractual obligations to any
                                        Supplier;
                                        <br />
                                        (4) relate to the access or use of Information by you or your Users;
                                        <br />
                                        (5) relate to the associated Operational Controls, sufficient to demonstrate compliance with the Agreement;
                                        <br />
                                        (6) are required in accordance with this Agreement or as notified by us to meet our record-keeping, reporting
                                        and payment obligations to Suppliers;
                                        <br />
                                        (b) You will make the records in clause 4.8(a) available to us, any Suppliers and any representative of a
                                        Supplier, on reasonable request, for audit purposes.
                                        <br />
                                        (c) If you have a right to access DBA Information, NZX Information, or ASX Information, you will ensure that
                                        DBA, NZX or ASX (as applicable) and any of their representatives have reasonable access to any relevant
                                        documents, reasonable access to your premises and reasonable access to any technical infrastructure for the
                                        purpose of carrying out an audit on the use of Information, the technical infrastructure or compliance with
                                        this Agreement.
                                        <br />
                                        (d) If you have a right to access NZX Information through your Account, then:
                                        <br />
                                        (1) if an audit reveals any underpayment of fees due to NZX in respect of your access to Information that
                                        exceeds 10% of the total paid to NZX for such access during the period covered by the audit and where
                                        requested by NZX, you shall pay the reasonable costs and expenses of the audit, including the reasonable cost
                                        of resolving any uncertainty or disagreement as to the amount due to NZX as a result of the audit; and
                                        <br />
                                        (2) NZX will provide at least thirty (30) calendar days of notice to you of an audit, but not if NZX has
                                        reason to suspect a material or repeated breach of the NZX Arrangement.
                                        <br />
                                        (e) If you have a right to access DBA Information or NZX Information through your Account, clauses 4.8(c) will
                                        apply for a period of two (2) years after the termination of either this Agreement or the agreement between us
                                        and the relevant Supplier for the supply of Information.
                                        <br />
                                        (f) If you have a right to access CDS Information through your Account, and you are not an employee or
                                        affiliate of JBWere, then you acknowledge that your access to the CDS Information may be terminated by JBWere
                                        upon 30 days’ notice to you or upon notice following a determination by JBWere or CDS that you are not
                                        providing accurate reports with respect to the use of CDS Information by you and your affiliates or are
                                        otherwise not in compliance with this Agreement.
                                        <br />
                                        (g) If you have a right to access ASX Information through your Account, then you acknowledge that any
                                        inspection by or on behalf of ASX will be conducted during normal business hours and subject to your
                                        reasonable security and confidentiality requirements.
                                        <br />
                                        <span>4.9 Reporting</span>
                                        <br />
                                        (a) If you have a right to access DBA Information through your Account:
                                        <br />
                                        (1) we will request that you provide us with an ‘Honesty Statement’ on at least a quarterly basis, until the
                                        15th day of the month following the end of each quarter. You must comply with these requests;
                                        <br />
                                        (2) the Honesty Statement must confirm the number of your Access IDs that are entitled to access each
                                        Information product provided by DBA; and
                                        <br />
                                        (3) you acknowledge DBA has the right to request that we de-entitle you, if you have failed more than once
                                        within a calendar year to comply with the deadline for submitting an Honesty Statements as is set out in this
                                        clause.
                                        <br />
                                        (b) If you have a right to access ASX Information through your Account, you will:
                                        <br />
                                        (1) comply with any reasonable request by us for you to regularly report data to us relating to your usage of
                                        the Information; and
                                        <br />
                                        (2) promptly advise us of any case where you have evidence or reasonable cause to believe that you are in
                                        breach of any one or more of the terms in this Agreement.
                                        <br />
                                        <span>4.10 Information security controls</span>
                                        <br />
                                        (a) If you have a right to access Real-time Information from DBA through your Account, you must ensure that
                                        all access to that DBA Information is controlled and recorded by one of the Units of Count.
                                        <br />
                                        (b) If you have a right to access NZX Information through your Account, you must ensure that your use and
                                        distribution of Real-time Information is subject to effective Operational Controls, except for the
                                        distribution of limited extracts of Information permitted under this Agreement. For the purpose of this
                                        clause, Operational Controls are regarded as effective in a given period if there is auditable evidence of
                                        their operation throughout the period.
                                        <br />
                                        <span>4.11 Intellectual property and other rights</span>
                                        <br />
                                        (a) You agree that the intellectual property rights in the Information belong to the relevant Supplier or
                                        provider of Information that has licensed the Supplier to make the Information available.
                                        <br />
                                        (b) For the avoidance of doubt, you agree that S&P, its affiliates, or its licensors own the Indexes and the
                                        Index Values, and all intellectual property rights therein, including, without limitation, any patent, trade
                                        secret, copyright, and trademark rights (whether or not such rights are registered).
                                        <br />
                                        (c) You recognise:
                                        <br />
                                        (1) our rights;
                                        <br />
                                        (2) the rights of Suppliers; and
                                        <br />
                                        (3) the rights of any provider who has licensed a Supplier to make Information available,
                                        <br />
                                        to legal remedies in respect of the Information.
                                        <br />
                                        <span>4.12 Commencing proceedings against a Supplier</span>
                                        <br />
                                        To the extent permitted by law, you agree not to make any claim or commence any proceedings directly against a
                                        Supplier.
                                        <br />
                                        <span>4.13 Termination</span>
                                        <br />
                                        (a) You acknowledge that we may unilaterally terminate your access to Information from a Supplier, at any time
                                        and without notice, upon receiving a direction to do so from that Supplier, who may give such a direction for
                                        any reason (for example, if the Supplier reasonably suspects that you have breached a term of this Agreement)
                                        or for no reason at all.
                                        <br />
                                        (b) You acknowledge that a Supplier may unilaterally terminate your access to Information from that Supplier,
                                        at any time and without notice, for any reason or for no reason at all.
                                    </TACContent>
                                </AccordionItem>
                                <br />
                                <AccordionItem label="5 Indemnity in favour of CDS" isOpen>
                                    <TACContent>
                                        If you have a right to receive CDS Information through your Account, to the extent permitted by law, you agree
                                        to indemnify, hold harmless and defend CDS from and against any and all suits, proceedings at law or in
                                        equity, and any and all liability, loss, damages and expenses (other than fees and expenses of attorneys
                                        separately retained by CDS), arising out of:
                                        <br />
                                        (a) your access to or use of CDS Information, unless the claim alleges that CBOE Data infringes the
                                        intellectual property rights of a third party or arises from gross negligence or wilful misconduct on the part
                                        of CDS;
                                        <br />
                                        (b) a breach of any of the representations, warranties, agreements or covenants under this Agreement by you;
                                        or
                                        <br />
                                        (c) a claim by any third party that JBWere’s provision of the CDS Information through your Account infringes
                                        any patent or violates any other property right; provided, however, that JBWere notifies you promptly in
                                        writing of any such suit or proceeding.
                                    </TACContent>
                                </AccordionItem>
                                <br />
                                <AccordionItem label="6 General" isOpen>
                                    <TACContent>
                                        <span>6.1 Governing law and jurisdiction</span>
                                        <br />
                                        (a) This Agreement is governed by the law in force in Victoria.
                                        <br />
                                        (b) Any court cases involving this Agreement and the transactions contemplated by it can be held in the courts
                                        of any state or territory of Australia with jurisdiction to consider matters related to this Agreement. The
                                        parties submit to the non-exclusive jurisdiction of the courts of that place. If the laws of the state or
                                        territory where you reside include mandatory protections that are available to you (including because you are
                                        an individual or small business), then this clause does not operate to exclude those protections.
                                        <br />
                                        <span>6.2 Invalidity and enforceability</span>
                                        <br />
                                        (a) If any provision of this Agreement is invalid under the law of any jurisdiction the provision is
                                        enforceable in that jurisdiction to the extent that it is not invalid, whether it is in severable terms or
                                        not.
                                        <br />
                                        (b) Clause 6.2(a) does not apply where enforcement of the provision of this Agreement in accordance with
                                        clause 6.2(a) would materially affect the nature or effect of the parties’ obligations under this Agreement.
                                        <br />
                                        <span>6.3 Waiver</span>
                                        <br />
                                        No party to this Agreement may rely on the words or conduct of any other party as a waiver of any right unless
                                        the waiver is in writing and signed by the party granting the waiver.
                                        <br />
                                        The meanings of the terms used in this clause 6.3 are set out below.
                                        <br />
                                        Terms and Meanings
                                        <br />
                                        Conduct: includes delay in the exercise of a right.
                                        <br />
                                        Right: any right arising under or in connection with this Agreement and includes the right to rely on this
                                        clause.
                                        <br />
                                        Waiver: includes an election between rights and remedies, and conduct which might otherwise give rise to an
                                        estoppel.
                                        <br />
                                        <span>6.4 Variation</span>
                                        <br />A variation of any term of this Agreement must be in writing and agreed to the parties. For the
                                        avoidance of doubt, this may be in the form of an electronic agreement (including a click wrap agreement).
                                        <br />
                                        <span>6.5 Assignment of rights</span>
                                        <br />
                                        (a) JBWere may, acting reasonably and having regard to its legitimate business interests, assign its rights
                                        under this Agreement without your consent.
                                        <br />
                                        (b) You may not assign any of your rights under this Agreement without the prior written consent of JBWere.
                                        <br />
                                        (c) A breach of clause 6.5(b) by you entitles JBWere to terminate this Agreement.
                                        <br />
                                        (d) Clause 6.5(c) does not affect the construction of any other part of this Agreement.
                                        <br />
                                        <span>6.6 Further action to be taken at each party’s own expense</span>
                                        <br />
                                        Each party must, at its own expense, do all things and execute all documents necessary to give full effect to
                                        this Agreement and the transactions contemplated by it.
                                        <br />
                                        <span>6.7 Relationship of the parties</span>
                                        <br />
                                        (a) Nothing in this Agreement gives a party authority to bind any other party in any way.
                                        <br />
                                        (b) Nothing in this Agreement imposes any fiduciary duties on a party in relation to any other party.
                                    </TACContent>
                                </AccordionItem>
                                <br />
                            </Accordion>
                            <br />
                        </>
                    </TACContainer>
                    <AcceptBtn variant="primary" iconToRight={true} label="Accept and Continue" onClick={handleAccept} />
                </TACContainerWithBtn>
            </TACMainContainer>
        </TACWrapper>
    );
}

export default TermsAndConditions;
