import { configMgr } from '@nab/nab-x-sdk-browser';
import { AuthenticateAction } from '@nab/x-types';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from, Observable } from '@apollo/client';

/**
 * This function returns the Apollo Client
 * @param authenticate {AuthenticateAction} - NAB-X Provides a function that is called authenticate, this will always return a token value either from a Cache or returned from DAF, For more information checkout the [quickstarts](http://go/nab-x-quickstarts)
 * @param traceId {string} -
 * @returns
 */
const getApolloClient = (authenticate: AuthenticateAction, traceId: string) => {
    const cache = new InMemoryCache();

    const headerLink = new ApolloLink((operation, forward) => {
        return new Observable(observable => {
            let sub = null;

            authenticate().then(token => {
                operation.setContext({
                    headers: {
                        authorization: `Bearer ${token.tokenValue}`,
                        'x-appd-operation-name': operation.operationName,
                        'x-nab-traceid': `interaction=${traceId}`
                    }
                })
                sub = forward(operation).subscribe(observable);
            });

            return () => (sub ? sub.unsubscribe() : null);
        });
    });

    const httpLink = new HttpLink({
        uri: `${configMgr.get('CLIENT_GATEWAY_URI') || configMgr.get('PUBLIC_PATH', '.')}${configMgr.get(
            'GRAPHQL_URI'
        ) || 'graphql'}`
    });

    return new ApolloClient({
        link: from([headerLink, httpLink]),
        cache,
        credentials: 'include'
    });
};

export { getApolloClient };