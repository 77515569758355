import styled from "styled-components";

type TMenuBarProps = {
    readonly isCollapsed?: boolean;
    readonly isTablet?: boolean;
    readonly subMenuOpen?: boolean;

};

const primaryMinWidth = '300px';
const primaryMaxWidth = '300px';
const secondaryMaxWidth = '300px';

export const PrimaryMenu = styled.div<TMenuBarProps>`
    display: block;
    overflow-x: auto;
    transition: left ease 150ms;
    flex-direction: column;
    width: 100%;
    min-width: ${primaryMinWidth};
    min-height: 100%;
    position: relative;
    left: ${(props) => !props.isTablet && props.subMenuOpen ? '-100%': '0%'} ;
    background-color: ${({theme}) => theme.color.white};
    max-width: ${(props) => props.isTablet ? primaryMaxWidth : '100%'};
`;

export const PrimaryList = styled.div<TMenuBarProps>`
    display: flex;
    overflow-x: auto;
    flex-direction: column;
    padding: ${(props) => !props.isTablet ? '1.5rem': '1.5rem 2rem'};
    width: 100%;
    min-width: ${primaryMinWidth};
    min-height: 100%;
    position: relative;
    background-color: ${({theme}) => theme.color.grey96};
    max-width: ${(props) => props.isTablet ? primaryMinWidth : '100%'};
`;

export const SecondaryList = styled.div<TMenuBarProps>`
    display: flex;
    flex-direction: column;
    transition: ${(props) => props.isTablet ? 'width ease 150ms' : 'right ease 150ms'};
    position: ${(props) => !props.isTablet ? 'absolute': 'relative'};
    right: ${(props) => !props.isTablet && !props.subMenuOpen ? '-100%': '0%'};
    width: ${(props) => props.isTablet && !props.subMenuOpen ? '0%': '100%'};
    height: ${({ isCollapsed }: any) => (isCollapsed ? '0rem' : '100%')};
    max-width: ${(props) => props.isTablet ? secondaryMaxWidth : '100%'};
    background-color: ${({ theme }) => theme.color.white};
`;

export const SecondaryListPanel = styled.div<TMenuBarProps>`
    display: ${(props) => !props.isTablet && !props.subMenuOpen ? 'none': 'block'};
    padding: ${(props) => !props.isTablet ? '1.5rem': '1.5rem 2rem'};
    min-width: 275px;
    position: relative;
    transition: opacity ease 150ms;
    opacity: ${(props) => props.isTablet && !props.subMenuOpen ? '0%': '100%'};
`;
export const StyledHr = styled.hr`
    padding: 0;
    margin: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.color.grey90};
    border: none;
`

export const MenuLabel = styled.div`
    font-weight: 600;
`

export const BackToMainMenu = styled.div`
@media (max-width: 398px) {
    padding: 1rem 1rem;
}
    padding: 1rem 0rem;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    display: flex;
    gap: 1rem;
    color: ${({ theme }) => theme.color.primary};

    & svg {
        color: ${({ theme }) => theme.color.primary};
    }
`

export const SecondaryListLabel = styled.div`
    padding: 1rem 1rem 1rem 0;
    height: 48px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.semibold};
`

export const SecondaryListItem = styled.li`
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 1.2rem;
        list-style: none;
        background-color: ${({theme}) => theme.color.white};
        
        a {
            color: ${({theme}) => theme.color.black};
            text-align: left;
            text-decoration: none;
        }

        &:focus-within, &:focus {
            font-weight: ${({ theme }) => theme.font.weight.regular};
            color: ${({theme}) => theme.color.white};
            background-color: ${({theme}) => theme.color.grey30} !important;
            outline-offset: -3px;
            outline: 3px solid ${({theme}) => theme.color.grey50};

            a {
                text-decoration: none;
                color: ${({theme}) => theme.color.white} !important;
            }
        }

        &.active, &:active {
            font-weight: ${({ theme }) => theme.font.weight.regular};
            background-color: #001E55;

            a {
                color: ${({theme}) => theme.color.white};
            }

            &:focus-within, &:focus {
                font-weight: ${({ theme }) => theme.font.weight.regular};
                color: ${({theme}) => theme.color.white};
                background-color: ${({theme}) => theme.color.grey30};
                outline-offset: -3px;
                outline: 3px solid ${({theme}) => theme.color.grey50};

                a {
                    color: ${({theme}) => theme.color.white};
                }
            }
        }
`

export const DisabledSecondaryListItem = styled.li`
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 1.2rem;
        list-style: none;
        background-color: ${({theme}) => theme.color.white};

        a {
            color: ${({theme}) => theme.color.black};
            text-align: left;
            text-decoration: none;
        }
`
export const PrimaryMenuListItem = styled.li`
    color: ${({ theme }) => theme.color.black};
    display: flex;
    height: 48px;
    position: relative;
    align-items: center;
    transition-duration: .2s;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    border-left: 4px solid ${({theme}) => theme.color.grey96};
    border-top: 1px solid ${({theme}) => theme.color.grey90};

    &:focus-within,&:focus {
        border-left: 4px solid ${({theme}) => theme.color.grey30};

        & a {
            text-decoration: underline;
        }
    }

    &.active, &.active:focus-within, &.active:focus {
        border-left: 4px solid #001E55;
    }

    a {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        color: ${({ theme }) => theme.color.black};
        text-align: left;
        padding: 1rem;
        position: relative;
        text-decoration: none;
        transition: 0.2s;
        cursor: pointer;
        white-space: nowrap;
        outline-style: none;
    }
    
`

export const IconHeight  = styled.span`
@media (max-width: 1024px) {
& svg {
    height:1.1rem;
} 
}  
    
`
export const MainMenuBottomspace = styled.span`
padding-top: 3px;
`
