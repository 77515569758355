import React from 'react';
import styled from 'styled-components';

/**
 * Provides the visual wrapper in which to render the mini-app that corresponds with the current URL
 */
export const AppViewport = styled.div`
  width: 100%;
  min-height: 78vh;
`;

export default AppViewport;
