import { configMgr } from '../config/config';
import {
    getUserBearerToken,
    getUserBearerTokenExpiry,
    getUserBearerTokenTimeout,
    getUserModTokenExpiryV1,
    getUserModTokenExpiryV2,
    setUserBearerTokenTimeout
} from '../localManager';

// this checks the DAF tokens along with their idle time and expiry
export const isDafUserSessionGood = () => {
    let isDafUserSessionGood = true;

    if (!getUserBearerToken()) {
        isDafUserSessionGood = false;
    }

    if (getUserBearerTokenExpiry()) {
        if (Date.now() > parseInt(getUserBearerTokenExpiry())) {
            isDafUserSessionGood = false;
        }
    }

    return isDafUserSessionGood;
};

// this checks the MOD tokens timeout
export const isModUserSessionGood = () => {
    let isModUserSessionGood = true;

    if (getUserModTokenExpiryV1()) {
        if (Date.now() > parseInt(getUserModTokenExpiryV1())) {
            isModUserSessionGood = false;
        }
    } else {
        isModUserSessionGood = false;
    }

    if (getUserModTokenExpiryV2()) {
        if (Date.now() > parseInt(getUserModTokenExpiryV2())) {
            isModUserSessionGood = false;
        }
    } else {
        isModUserSessionGood = false;
    }

    return isModUserSessionGood;
};
