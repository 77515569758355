import styled from 'styled-components';
import { Link, Container, Col, LinkIcon, HelpIcon, CallIcon } from '@nab/nui-react';

export const FormDiv = styled.div`
    color: ${({ theme }) => '#F5F5F5'};
    position: relative;
    overflow: hidden;
    width: 100%;
    justify-content: space-evenly;

    @media (min-width: 768px) {
        display: flex;
    }
`;

export const BorderDiv = styled.div`
    width: 100%;
`;

export const LogoImage = styled.img`
    height: 1.5rem;
`;

export const StyledLine = styled.div`
    width: 100%;
    border-top: 1px solid #a3adb2;
`;

export const StyledParagraph = styled.div`
    font-size: 0.75rem;
    padding-top: 0.3125rem;
`;

export const FooterContainer = styled.div`
    background: #001e55;
    width: 100%;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    
    color: ${({ theme }) => '#F5F5F5'};

    display: flex;
    align-items: baseline;
    justify-content: space-between;
    @media (min-width: 1350px) {
        justify-content: space-evenly;
    }

    @media (min-width: 700px) {
        flex-wrap: wrap;
    }
    @media (max-width: 576px) {
        flex-wrap: wrap;
        padding-left: 4px;
        padding-top: 0px;
    }
    @media print {
        display: none;
    }
`;

export const FooterContents = styled.div`
    display: flex;
    align-items: baseline;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        line-height: 4rem;
        margin-left: 0px;
    }
    @media (max-width: 576px) {
        padding-left: 0px;
    }
    @media (min-width: 1350px) {
        margin-left: -2rem;
    }
`;

export const FooterText = styled.div`
    margin-left: 20px;
`;

export const CopyrightsText = styled.div`
    @media (min-width: 768px) {
        padding-top: 20px;
        padding-left: 20px;
    }
    @media (max-width: 576px) {
        margin-top: 55px;
        padding-left: 20px;
    }
    @media (min-width: 1140px) and (max-width: 1350px) {
        margin-right: 22px;
    }
`;

export const FooterLayout = styled.div`
    width: 95%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    align-items: center;
    @media (max-width: 30rem) {
        align-items: start;
    }
`;
export const FooterLayoutColumnOne = styled.div`
    display: flex;
    gap: 1.25rem;
    padding-bottom: 0.9375rem;
    text-decoration-line: underline;
    @media (max-width: 30rem) {
        flex-direction: column;
        width: 100%;
    }
`;
export const FooterLinkContactUs = styled(Link)`
    color: ${({ theme }) => '#F5F5F5'};
    &:hover {
        color: ${({ theme }) => theme.color.grey50};
    }
    &:focus {
        color: ${({ theme }) => theme.color.grey96};
    }
`;

export const StyledLInk = styled(Link)`
    gap: 0.3125rem;
    color: ${({ theme }) => '#F5F5F5'};
    &:hover {
        color: ${({ theme }) => theme.color.grey50};
    }
    &:focus {
        color: ${({ theme }) => theme.color.grey96};
    }
`;

export const ImportantInformationContainer = styled.div`
    width: 95%;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
`;

export const FooterLayoutContainer = styled(Container)`
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    padding: 20px;
`;

export const StyledTextAlignCol = styled(Col)`
    text-align: end;
    padding-bottom: 20px;
    padding-left: 10px;
    @media (max-width: 30rem) {
        text-align: start;
    }
`;

export const StyledCallIcon = styled(CallIcon)`
    margin-left: 5px;
    color: ${({ theme }) => '#F5F5F5'} !important;
    &:hover {
        color: ${({ theme }) => theme.color.grey50};
    }
`;

export const StyledLinkIcon = styled(LinkIcon)`
    color: ${({ theme }) => '#F5F5F5'} !important;
    &:hover {
        color: ${({ theme }) => theme.color.grey50};
    }
`;

export const StyledHelpIcon = styled(HelpIcon)`
    color: ${({ theme }) => '#F5F5F5'} !important;
    &:hover {
        color: ${({ theme }) => theme.color.grey50};
    }
`;

export const StyledJBWereTermsCol = styled(Col)`
    padding-left: 18px;
`;

export const StyledJBWerePrivacyPolicyCol = styled(Col)`
    padding-left: 1rem;
    @media (max-width: 30rem) {
        padding-left: 0px;
    }
`;

export const StyledBr = styled.br`
    display: none;
    @media (min-width: 768px) and (max-width: 1000px) {
        display: inline;
    }
`;
