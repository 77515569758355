import { Notification, Paragraph } from '@nab/nui-react';
import styled from 'styled-components';
import jbWereBackground from '../../images/jbwereportal_login.jpg';

export const SpaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
    background: url(${jbWereBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    height: 100%;
`;

//Login Box
export const FormDiv = styled.div`
    width: 100%;
    justify-content: space-evenly;
    margin-top: 1.5rem;
    overflow: hidden;
    @media (min-width: 768px) {
        display: flex;
    }
    @media (min-width: 840px) {
        min-height: auto;
    }
`;

//Login Box
export const BorderDiv = styled.div`
    width: auto;
    max-width: 510px;
    border-radius: 8px;
    margin: 0 1.5rem 1.5rem;
    background-color: ${({ theme }) => theme.color.white};
`;

//Maintanence Notification
export const MaintanenceNotification = styled.div`
    min-height: 86px;
    margin: 1.5rem 1.5rem 0 1.5rem;
    border-radius: 8px;
    div[data-component-id='Notification'] {
        border-radius: 8px;
    }
    @media only screen and (min-width: 560px) and (max-width: 839px) {
        width: 94%;
    }
`;
