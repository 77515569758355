export const getBearerToken = (): string | null => sessionStorage.getItem('bearerToken');

export const getBearerTokenTimeout = (): string | null => sessionStorage.getItem('bearerTokenTimeout');

export const getBearerTokenExpiry = (): string | null => sessionStorage.getItem('bearerTokenExpiry');

export const getModTokenV1 = (): string | null => sessionStorage.getItem('modTokenV1');

export const getModTokenV2 = (): string | null => sessionStorage.getItem('modTokenV2');

export const getIdentityInfo = (): string | null => sessionStorage.getItem('identityInfo');

export const setBearerTokenTimeout = (bearerTokenTimeout): void => sessionStorage.setItem('bearerTokenTimeout', bearerTokenTimeout);

export const setBearerTokenExpiry = (bearerTokenExpiry): void => sessionStorage.setItem('bearerTokenExpiry', bearerTokenExpiry);

export const setModTokenV1 = (modTokenV1): void => sessionStorage.setItem('modTokenV1', modTokenV1);

export const setModTokenV2 = (modTokenV2): void => sessionStorage.setItem('modTokenV2', modTokenV2);

export const getUserGroup = (): string | null => sessionStorage.getItem('userGroup');

export const setUserGroup = (userGroup): void => sessionStorage.setItem('userGroup', userGroup);

export const setUserInfo = (identityInfo): void => sessionStorage.setItem('identityInfo', JSON.stringify(identityInfo));

export const setIsClient = (isClient): void => sessionStorage.setItem('isClient', isClient);

export const getIsClient = (): string | null => sessionStorage.getItem('isClient');

export const setClientToken = (currentClientToken): void => sessionStorage.setItem('currentClientToken', currentClientToken);

export const getClientToken = (): {} | null => sessionStorage.getItem('currentClientToken');

export const setClientAccountToken = (currentClientAccountToken): void =>
    sessionStorage.setItem('currentClientAccountToken', currentClientAccountToken);

export const getClientAccountToken = (): {} | null => sessionStorage.getItem('currentClientAccountToken');
export const getClientAccountcpCode = (): {} | null => sessionStorage.getItem('currentClientAccountcpCode');
export const getClientAccountcpName = (): {} | null => sessionStorage.getItem('currentClientAccountcpName');

export const removeModTokenV1 = (): void => sessionStorage.removeItem('modTokenV1');
export const removeModTokenV2 = (): void => sessionStorage.removeItem('modTokenV2');
export const removeIsClient = (): void => sessionStorage.removeItem('isClient');
