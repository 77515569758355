import React from 'react';
import styled from 'styled-components';
import { FatalError } from '@nab/nui-react';
import { UnpluggedIconComponent } from '../GlobalSpa/GlobalSpa.styles';

const FormDiv = styled.div`
    background-color: white;
    position: relative;
    overflow: hidden;
    width: 100%;
    justify-content: space-evenly;
    @media (min-width: 840px) {
        display: flex;
        min-height: 600px;
    }
`
const BorderDiv = styled.div`
    width: 50vw;
    padding: 60px 0 0 40px;
`

export function AccessDenied({ msg }) {
    return (
        <FormDiv>
            <BorderDiv>
                <FatalError 
                    pictogram={UnpluggedIconComponent}
                    heading={'JBWere is currently unavailable'}
                    paragraph={msg}
                />                
            </BorderDiv>
        </FormDiv>
    );
}

export default AccessDenied;