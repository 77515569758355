/* eslint-disable class-methods-use-this, no-console */
import { IRUMStrategy, IUserInfo } from '@nab/x-types';
import { RUMEventData, IRUMUserDataProvider } from '@nab/x-spa-react';
import { AppDRUMStrategy } from '@nab/nab-x-sdk-browser';
import { configMgr } from '../config/config'

export class LocalRumMonitoring implements IRUMStrategy {
    public emitEvent(eventData: RUMEventData, userDataProvider?: IRUMUserDataProvider): void {
        console.log('RUM.emitEvent', eventData, userDataProvider?.getDataForError?.());
    }

    public setUser(userData: IUserInfo) {}
}

export function makeRumStrategy(): IRUMStrategy {
    if (configMgr.get('APPD_RUMAGENT_APPKEY') === '') {
        return new LocalRumMonitoring();
    }

    return new AppDRUMStrategy({
        libraryUrl:
            configMgr.get('APPD_RUMAGENT_LIBRARYURL'),
        configValue: {
            appKey: configMgr.get('APPD_RUMAGENT_APPKEY'),
            adrumExtUrlHttp: 'http://x.nab.com.au/static-shared/nab/js/appd',
            adrumExtUrlHttps: 'https://x.nab.com.au/static-shared/nab/js/appd',
            beaconUrlHttp: 'http://syd-col.eum-appdynamics.com',
            beaconUrlHttps: 'https://syd-col.eum-appdynamics.com',
            xd: {
                enable: true
            },
            spa: {
                spa2: true
            }
        }
    });
}