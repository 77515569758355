import styled from 'styled-components';

export const SpaWrapper = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;

    @media (max-width: 640px) {
        min-height: 75vh;
    }

    @media (min-width: 640px) {
        min-height: 80vh;
    }

    @media (min-width: 800px) {
        min-height: 85vh;
    }

    .change-password {
        @media (min-width: 800px) and (max-width: 1139px) {
            max-width: 90%;
        }
        @media (min-width: 1140px) {
            max-width: 60%;
            margin: auto;
        }
    }
`;

export const PortalHeaderWrap = styled.div<{ newWindow: boolean }>`
    width: 100%;
    z-index: 20;
    display: ${props => (props.newWindow ? 'none' : 'block')};
`;
export const FooterContainer = styled.div<{ newWindow: boolean }>`
    display: ${props => (props.newWindow ? 'none' : 'block')};

    @media print {
        display: none;
    }
`;
export const MainContainer = styled.div`
    background-color: white;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
`;

export const MainLayout = styled.div<{ belowDesktop: boolean }>`
    max-width: 1252px;
    width: 100%;
    margin-top: ${props => (props.belowDesktop ? '1rem' : '')};
`;
