import React, { useEffect, useMemo, useState } from 'react';
import { SpaProps, useFlag, useFlagStrategy } from '@nab/x-spa-react';
import { getShellActions } from '@nab/x-runtime';
import { GlobalStyle, Theme, Spinner } from '@nab/nui-react';
import MainSpa from '../MainSpa/MainSpa';
import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from '../../utils/graphql/ApolloClient';
import { useTraceId } from '@nab/nab-x-react';
import AccessDenied from '../Error/AccessDenied';
import { LaunchDarklyFlagStrategy } from '../../utils/launchDarkly/LaunchDarklyFlagStrategy';
import { configMgr } from '../../utils/config/config';
import {
    ModTokenV1,
    ModTokenV2,
    SamlHandshake,
    SamlHandshakeForAdvisorAndClient,
    Logout,
    getUserNameInfo,
    callGtwHeartbeat,
    RevokeDafAuthToken
} from '../helper';
import { KeepAlive } from '@nab/nab-x-sdk-browser';
import { isDafUserSessionGood, isModUserSessionGood } from '../../utils/tokens/tokens';
import {
    getUserBearerToken,
    getUserModAlertProfileCreated,
    setUserFirstName,
    setUserModTokenExpiryV1,
    setUserModTokenExpiryV2,
    setUserModTokenV1,
    setUserModTokenV2,
    setUserBearerTokenTimeout,
    getUserBearerTokenTimeout
} from '../../utils/localManager';
import SessionMgtTimer from '../SessionMgtTimer/SessionMgtTimer';

import {
    getBearerToken,
    getModTokenV1,
    getModTokenV2,
    setModTokenV1,
    setModTokenV2,
    setUserGroup,
    getUserGroup,
    setIsClient,
    getIsClient,
    getIdentityInfo,
    removeIsClient,
    removeModTokenV1,
    removeModTokenV2
} from '../../utils/sessionManager';
import TermsAndConditions from '../common/TermsAndConditions';

/**
 * you can pass the following additional parameters in the PortalSpa:
 * initError, routeError, ...others
 */
export default function PortalSpa({ context, activeAppInstance, actions, appConfig }: SpaProps) {
    useFlagStrategy(new LaunchDarklyFlagStrategy(configMgr.get('LAUNCHDARKLY_CLIENT_ID')));
    const portalUnlockedFlag = useFlag<boolean>('jbw-portal-unlocked', false);
    const portalUserInfoFlag = useFlag<boolean>('jbw-userinfo-enabled', false);
    const portalHeartbeatFlag = useFlag<boolean>('jbw-heartbeat-enabled', false, true);

    const [loadingStatus, setLoadingStatus] = useState({
        loading: true,
        message: 'Initializing user access and page rights. Please wait...'
    });

    const [idleTimerRevokeStatus, setIdleTimerRevokeStatus] = useState<boolean>(false);

    const augmentedAppInstance = useMemo(() => {
        const appData = activeAppInstance?.appData ? activeAppInstance.appData : {};
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.forEach((value, key) => {
            appData[key] = value;
        });

        return !activeAppInstance
            ? activeAppInstance
            : {
                  ...activeAppInstance,
                  appData: appData
              };
    }, [activeAppInstance]);
    const domFromXml = xmlString => {
        const parser = new DOMParser();
        try {
            return parser.parseFromString(xmlString, 'application/xml');
        } catch (e) {
            console.error('XML Parsing failed', e);
        }
    };

    const groupsFromSaml = xmlDocument => {
        if (xmlDocument) {
            const groupList = xmlDocument.querySelectorAll("Attribute[Name='Groups'] > *");
            const groups = [];
            groupList.forEach(item => {
                groups.push((item.textContent || '').trim());
            });
            return groups;
        }
    };
    const identityInfo = JSON.parse(getIdentityInfo());

    const checkAdvisorAndClient = samlHandshake => {
        setUserGroup(groupsFromSaml(domFromXml(atob(samlHandshake?.data?.access_token))));
        if (identityInfo != null && localStorage.getItem(identityInfo?.username) != null) {
            if (getUserGroup() !== 'STAFF' && getUserGroup() !== 'INT') {
                setIsClient(true);
                getShellActions().navigate('/portal/portfolio/portfolio-summary');
            } else {
                setIsClient(false);
                getShellActions().navigate('/portal/profile-help/client-list');
            }
        }
    };

    const HeartbeatImpl = (token: any) => {
        if (getBearerToken() === token && portalHeartbeatFlag) {
            return callGtwHeartbeat(token);
        }
    };

    const UserInActive = () => {
        if (getUserBearerTokenTimeout()) {
            setIdleTimerRevokeStatus(true);
        }
    };

    const keepAlive = new KeepAlive({
        idleTime: 675, // 75% of 900 seconds
        heartbeatProvider: HeartbeatImpl,
        onUserInActive: UserInActive
    });

    useEffect(() => {
        if (getBearerToken()) {
            SamlHandshake(getBearerToken())
                .then(async samlHandshake => {
                    try {
                        if (!getModTokenV2() || !getModTokenV1()) {
                            ModTokenV2(samlHandshake?.data?.access_token).then(modResponse => {
                                setModTokenV2(modResponse?.data?.access_token);
                                setModTokenV1(modResponse?.data?.access_token);
                            });
                        }
                        if (!getIsClient()) checkAdvisorAndClient(samlHandshake);
                    } catch (err) {
                        Logout({
                            revokeDafAuthToken: false
                        });
                    }
                })
                .catch(error => {
                    Logout({
                        revokeDafAuthToken: false
                    });
                });
        }

        if (portalUserInfoFlag && getBearerToken()) {
            getUserNameInfo(getBearerToken()).then(async userInfoData => {
                setUserFirstName({
                    nameInfo: identityInfo?.username ? identityInfo?.username : '',
                    userFirstName: userInfoData?.data?.given_name ? userInfoData?.data?.given_name : ''
                });
            });
        }
    }, []);

    const init = async () => {
        if (!isDafUserSessionGood()) {
            setLoadingStatus({ ...loadingStatus, message: 'User access failed. Redirecting to the login page...' });
            await Logout({
                revokeDafAuthToken: true
            });
        }
    };

    useEffect(() => {
        setUserBearerTokenTimeout((Date.now() + parseInt(configMgr.get('DAF_IDLE_TIMEOUT_SECS')) * 1000).toString());
        keepAlive.init();
        return () => {
            keepAlive.destroy();
        };
    }, []);

    useEffect(() => {
        init();
    }, [window.location.href]);

    const client = getApolloClient(actions.authenticate, useTraceId().traceId);
    if (!augmentedAppInstance) {
        return;
    }
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme theme="jbwere">
                <>
                    {!portalUnlockedFlag ? (
                        <AccessDenied msg={'You do not have access to the JBWere portal'} />
                    ) : identityInfo != null && localStorage.getItem(identityInfo?.username) == null ? (
                        <TermsAndConditions />
                    ) : (
                        <>
                            <SessionMgtTimer idleTimerRevokeStatus={idleTimerRevokeStatus} />
                            <ApolloProvider client={client}>
                                <MainSpa
                                    activeAppInstance={augmentedAppInstance}
                                    activeRoute={activeAppInstance && activeAppInstance.app.route}
                                    subRoute={activeAppInstance && activeAppInstance.app.route + activeAppInstance.appConfig.startingRoute}
                                    items={(context && context.menu) || []}
                                    baseRoute={activeAppInstance && appConfig && appConfig.route ? appConfig.route.matchedPath : '/'}
                                    navigate={actions && actions.navigate}
                                    actions={actions}
                                />
                            </ApolloProvider>
                        </>
                    )}
                </>
            </Theme>
        </>
    );
}
