import React, { useRef, useEffect, useState } from 'react';
import { ChevronDownIcon } from '@nab/nui-react';
import NavSubItem from './NavSubItem';
import { StyledHeaderNavItem } from './SubHeaderNav.styles';
import { getIsClient } from '../../../utils/sessionManager';

interface NavItemInterface {
    key: any;
    index: any;
    activeRoute: any;
    subRoute: any;
    baseRoute: any;
    item: any;
    actions: any;
    currentActiveIndex: any;
    setNavItemIndex: any;
    isClient: any;
}

export function NavItem({ index, activeRoute, subRoute, baseRoute, item, actions, currentActiveIndex, setNavItemIndex, isClient }: NavItemInterface) {
    const ref = useRef(null);

    const setActiveIndex = index => {
        if (currentActiveIndex !== index) {
            ref.current.focus();
            setNavItemIndex(index);
        } else {
            ref.current.blur();
            setNavItemIndex(null);
        }
    };

    const CommonMenu = item.children;
    const filteredMenuitem = item.children.filter(i => {
        return i.label != 'Client list';
    });
    const AdvisorfilteredMenuitem = item.children.filter(i => {
        return i.label != 'Profile';
    });

    if (isClient === null) {
        isClient = getIsClient();
    }
    if (isClient === 'true') {
        return (
            <StyledHeaderNavItem
                tabIndex={-index}
                data-testid="styled-header-nav-item"
                className={`navbar_item navbar_header-item ${item.children.map(e => e.route).includes(subRoute) ? 'active' : ''}`}
                ref={ref}
                onClick={() => setActiveIndex(index)}
            >
                <a className="navbar_link navbar_header-link">
                    {item.label}
                    {item.children.length > 0 && (
                        <span className="icon-wrap">
                            <ChevronDownIcon data-testid="chevron-icon" />
                        </span>
                    )}
                </a>
                <ul className="navbar_sub-menu">
                    <div className="navbar_sub-menu-panel" data-testid="nav-sub-item">
                        {filteredMenuitem.map((i, index) => (
                            <NavSubItem
                                key={i.route}
                                route={i.route}
                                index={index}
                                subRoute={subRoute}
                                baseRoute={baseRoute}
                                label={i.label}
                                icon={i.icon}
                                actions={actions}
                                item={item}
                            />
                        ))}
                    </div>
                </ul>
            </StyledHeaderNavItem>
        );
    } else if (isClient === 'false') {
        return (
            <StyledHeaderNavItem
                tabIndex={-index}
                data-testid="styled-header-nav-item"
                className={`navbar_item navbar_header-item ${item.children.map(e => e.route).includes(subRoute) ? 'active' : ''}`}
                ref={ref}
                onClick={() => setActiveIndex(index)}
            >
                <a className="navbar_link navbar_header-link">
                    {item.label}
                    {item.children.length > 0 && (
                        <span className="icon-wrap">
                            <ChevronDownIcon data-testid="chevron-icon" />
                        </span>
                    )}
                </a>
                <ul className="navbar_sub-menu">
                    <div className="navbar_sub-menu-panel" data-testid="nav-sub-item">
                        {AdvisorfilteredMenuitem.map((i, index) => (
                            <NavSubItem
                                key={i.route}
                                route={i.route}
                                index={index}
                                subRoute={subRoute}
                                baseRoute={baseRoute}
                                label={i.label}
                                icon={i.icon}
                                actions={actions}
                                item={item}
                            />
                        ))}
                    </div>
                </ul>
            </StyledHeaderNavItem>
        );
    }
}

export default NavItem;
