import React, { useCallback, useState } from 'react';
import {
    Sidebar,
    LoginDetailsWrap,
    MenuBody,
    SidebarContainer,
    MenuHeader,
    HamburgerLogoWrap,
    MenuBodyContent,
    MenuBodyContentLists,
    SideMenuFooter,
    HamburgerIconWrapper,
    LogoWrap,
    StyledSquareButtonRow,
    SquareButton,
    MenuBodyBackgroundOverlay,
    TabletButtonWrap,
} from './SideMenuNav.styles';
import { PrimaryMenu,
    PrimaryList } from './SideMenuNavList.styles'
import JBWereLogo from '../../../images/jbWere_Logo.svg'
import {
    HamburgerIcon,
    ButtonIcon,
    CloseIcon,
    Row,
    Link as NuitLink,
    LogOutIcon, Button
} from '@nab/nui-react';
import { AppActions, AppInstance, MenuNode, SpaConfig } from '@nab/x-spa-react';
import {Logout} from "../../helper";
import SubHeaderAccountWelcome from "../SubHeaderNav/SubHeaderWelcome";
import SideMenuPrimaryListItem from "./SideMenuPrimaryListItem";
import SideMenuSecondaryMenu from "./SideMenuSecondaryMenu";


interface IMenu {
    isTablet;
    activeRoute;
    subRoute;
    baseRoute;
    navigate;
    loading;
    error;
    userLoginDetails;
    activeAppInstance: AppInstance;
    items: MenuNode[];
    appConfig: SpaConfig;
    actions: AppActions;
}

export default function SideMenuNav({ isTablet, activeRoute, subRoute, baseRoute, loading, error, userLoginDetails, items, actions }: IMenu) {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const toggle = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed, setIsCollapsed]);

    const[currentActiveIndex, setCurrentActiveIndex] = useState(-1);
    const subMenuOpen = currentActiveIndex >= 0;

    return (
        <SidebarContainer data-testid="sidebar-container">
            <Sidebar isCollapsed={isCollapsed} data-testid="sidebar">
                <MenuHeader isCollapsed={isCollapsed} id="header" data-testid="menu-header">
                    <Row fluid noSideGutters alignSelf="center" align-content={'center'} justifyContent={'between'} flexWrap={'wrap'}>
                        <HamburgerLogoWrap data-testid="hamburger-logo-wrap">
                            <HamburgerIconWrapper isTablet={isTablet} data-testid="hamburger-icon-wrapper">
                                <ButtonIcon color="primary" isOnDarkBg={true} label="Menu" icon={isCollapsed ? HamburgerIcon : CloseIcon} onClick={toggle} />
                            </HamburgerIconWrapper>
                            <NuitLink variant="standard" href="/">
                                <LogoWrap>
                                    <img alt={'JBWereLogo'} src={JBWereLogo} />
                                </LogoWrap>
                            </NuitLink>
                        </HamburgerLogoWrap>
                        {isTablet && (
                            <TabletButtonWrap data-testid="tablet-button-wrap">
                                <Button variant="primary" icon={LogOutIcon} iconToRight={true} label="Log out" onClick={Logout} />
                            </TabletButtonWrap>
                        )}
                        {!isTablet && (
                            <StyledSquareButtonRow fluid noSideGutters justifyContent={'end'}>
                                <SquareButton isPrimary={true} onClick={Logout}>
                                    <LogOutIcon size="sm" />
                                    Logout
                                </SquareButton>
                            </StyledSquareButtonRow>
                        )}
                    </Row>
                </MenuHeader>
                <MenuBody isCollapsed={isCollapsed}>
                    <MenuBodyContent isCollapsed={isCollapsed} isTablet={isTablet}>
                        <MenuBodyContentLists>
                            <PrimaryMenu isCollapsed={isCollapsed} isTablet={isTablet} subMenuOpen={subMenuOpen}>
                                    <PrimaryList isCollapsed={isCollapsed} isTablet={isTablet} subMenuOpen={subMenuOpen}>
                                    {items.map((item, index) => (
                                        <SideMenuPrimaryListItem
                                            isTablet={isTablet}
                                            key={item.route}
                                            index={index}
                                            subRoute={subRoute}
                                            baseRoute={baseRoute}
                                            item={item}
                                            items={items}
                                            actions={actions}
                                            currentActiveIndex={currentActiveIndex}
                                            setNavItemIndex={setCurrentActiveIndex}
                                        />
                                    ))}
                                    <SideMenuFooter isCollapsed={isCollapsed}>
                                        <LoginDetailsWrap>
                                            <SubHeaderAccountWelcome loading={loading} error={error} userLoginDetails={userLoginDetails} />
                                        </LoginDetailsWrap>
                                    </SideMenuFooter>
                                </PrimaryList>
                            </PrimaryMenu>
                            <SideMenuSecondaryMenu
                                subMenuOpen={subMenuOpen}
                                isTablet={isTablet}
                                item={items[currentActiveIndex]}
                                subRoute={subRoute}
                                baseRoute={baseRoute}
                                actions={actions}
                                currentActiveIndex={currentActiveIndex}
                                setNavItemIndex={setCurrentActiveIndex}
                                setIsCollapsed={setIsCollapsed}
                            />
                            {isTablet && (
                                <MenuBodyBackgroundOverlay
                                    onClick={() => {
                                        setCurrentActiveIndex(-1);
                                        setIsCollapsed(true);
                                    }}
                                    isCollapsed={isCollapsed}
                                />
                            )}
                        </MenuBodyContentLists>
                    </MenuBodyContent>
                </MenuBody>
            </Sidebar>
        </SidebarContainer>
    );
}
