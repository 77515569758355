import React from 'react';
import { HeaderContainer, HeaderLayout, LogoContainer, FormDiv, BorderDiv } from '../Header/Header.styles';
import { Container, Row, Col } from '@nab/nui-react';
import JBWereLogo from '../../images/jbWere_Logo.svg';

export function LoginHeader() {
    return (
        <FormDiv data-testid="form-div">
            <BorderDiv data-testid="border-div">
                <Container data-testid="container" fluid noSideGutters>
                    <HeaderContainer data-testid="header-container">
                        <HeaderLayout data-testid="header-layout">
                            <Row data-testid="row">
                                <Col data-testid="col" xl="6" lg="6" md="6" sm="6" xs="12">
                                    <LogoContainer data-testid="logo-img" src={JBWereLogo} />
                                </Col>
                            </Row>
                        </HeaderLayout>
                    </HeaderContainer>
                </Container>
            </BorderDiv>
        </FormDiv>
    );
}

export default LoginHeader;
