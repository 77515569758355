import { UnpluggedIcon } from "@nab/nui-react";
import styled from "styled-components";

export const SpaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  flex: 1;

  [aria-hiddn="true"] {
    display: none;
  }
`;
export const UnpluggedIconComponent = styled(UnpluggedIcon)`
    path {
        fill: #001e55;
    }
`;