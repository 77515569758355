import React, { } from 'react';
import {
    HeaderWelcomeTemplate,
    SkeletonWidth, WelcomeErrorIcon, WelcomeSubTitle, WelcomeTitle,
} from './SubHeaderNav.styles';
import {format} from 'date-fns';
import {ErrorOutlineIcon, SkeletonLoader} from '@nab/nui-react';
import { getUserFirstName  } from '../../../utils/localManager';
import {
    getIdentityInfo
} from '../../../utils/sessionManager';
export function SubHeaderWelcome(
    {loading, error, userLoginDetails}
) {
    let nameObj : any
    const userName = getUserFirstName();
    const identityInfo = JSON.parse(getIdentityInfo());
    if(userName){
         nameObj= userName.find(x => x.nameInfo === identityInfo?.username);
    }
    
        if (loading) {
        return (
            <HeaderWelcomeTemplate data-testid="headerWelcomeTemplateLoading">
                <span className="icon">
                    <SkeletonLoader size={25} variant="circle" srText="Loading"></SkeletonLoader>
                </span>
                <div>
                    <WelcomeTitle>
                        <SkeletonWidth>
                            <SkeletonLoader size={20} variant="rect" srText="Loading"></SkeletonLoader>
                        </SkeletonWidth>
                    </WelcomeTitle>
                    <WelcomeSubTitle>
                        <SkeletonWidth>
                            <SkeletonLoader size={14} variant="rect" srText="Loading"></SkeletonLoader>
                        </SkeletonWidth>
                    </WelcomeSubTitle>
                </div>
            </HeaderWelcomeTemplate>
        )
    }

    if (error) {
        return (
            <HeaderWelcomeTemplate data-testid="headerWelcomeTemplateError">
                <WelcomeErrorIcon>
                    <ErrorOutlineIcon size="md" color="primary" />
                </WelcomeErrorIcon>
                <div>
                    <WelcomeTitle>
                        Something went wrong
                    </WelcomeTitle>
                    <WelcomeSubTitle>
                        An error occurred while fetching the data
                    </WelcomeSubTitle>
                </div>
            </HeaderWelcomeTemplate>
        )
    }

    return (
        <HeaderWelcomeTemplate data-testid="headerWelcomeTemplateLoaded">
                <WelcomeTitle>
                   Welcome {nameObj?.userFirstName ? nameObj?.userFirstName : ''}<br/>
                </WelcomeTitle>     
        </HeaderWelcomeTemplate>
    );
}

export default SubHeaderWelcome;
