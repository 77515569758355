import React, {useRef} from 'react';
import {ChevronRightIcon} from "@nab/nui-react";
import {
    MenuLabel,
    PrimaryMenuListItem,
} from "./SideMenuNavList.styles";

interface NavItemInterface {
    index: any,
    subRoute: any,
    item: any,
    currentActiveIndex: any,
    setNavItemIndex: any
}

export function SideMenuPrimaryListItem({index, subRoute, item, currentActiveIndex, setNavItemIndex}: NavItemInterface) {

    const ref = useRef(null);

    const setActiveIndex = (index) => {
        if(currentActiveIndex !== index) {
            ref.current.focus();
            setNavItemIndex(index);
        } else {
            ref.current.blur();
            setNavItemIndex(-1)
        }
    }

    return (
        <PrimaryMenuListItem data-testid="primary-menu-list-item" tabIndex={-index}
                             className={`${item.children.map(e => e.route).includes(subRoute) ? 'active' : ''}`}
                             ref={ref}
                             onClick={() => setActiveIndex(index)}
        >
            <a>
                <MenuLabel data-testid="menu-label">{item.label}</MenuLabel>
                {item.children.length > 0 &&
                    <span>
                        <ChevronRightIcon data-testid="icon"/>
                    </span>
                }
            </a>
        </PrimaryMenuListItem>
    );
}

export default SideMenuPrimaryListItem;
