import { DafGrantType, getShellActions, removeToken, AuthContext } from '@nab/x-spa-react';
import axios from 'axios';
import { configMgr } from '../utils/config/config';
import { getSessionUserBearerToken, removeLocalStorageItemsByKeyPrefix, setUserFirstName, setUserBearerTokenTimeout } from '../utils/localManager';
const qs = require('qs');

export const SamlHandshake = async bearerToken => {
    const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);
    try {
        return await axios.post(
            configMgr.get('DAF_URL') + '/v1/idp/oauth/token',
            {
                client_id: identityConfig.data['apps'][0]['appConfig']['clientId'],
                grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
                requested_token_type: 'urn:ietf:params:oauth:token-type:saml2',
                audience: configMgr.get('DAF_SAML_HANDSHAKE_AUDIENCE'),
                subject_token: bearerToken,
                subject_token_type: 'urn:ietf:params:oauth:token-type:access_token'
            },
            {
                withCredentials: true
            }
        );
    } catch (error) {
        console.error('Retrieving SAML2 token failed ', error);
    }
};

export const SamlHandshakeForAdvisorAndClient = async bearerToken => {
    const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);
    try {
        return await axios.post(
            configMgr.get('DAF_URL') + '/v1/idp/oauth/token',
            {
                client_id: identityConfig.data['apps'][0]['appConfig']['clientId'],
                grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
                requested_token_type: 'urn:ietf:params:oauth:token-type:saml2',
                audience: 'jbwere_client',
                subject_token: bearerToken,
                subject_token_type: 'urn:ietf:params:oauth:token-type:access_token'
            },
            {
                withCredentials: true
            }
        );
    } catch (error) {
        console.error('Retrieving SAML2 token failed for Advisor and Client', error);
    }
};

export const getUserNameInfo = async bearerToken => {
    const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);
    console.log('identityConfig.data', identityConfig.data);
    try {
        return await axios.get(configMgr.get('DAF_ENDPOINT_URL_USER_INFO'), {
            headers: {
                Authorization: `Bearer ${bearerToken}`
            }
        });
    } catch (error) {
        console.error('User info error', error);
    }
};

export const callGtwHeartbeat = async bearerToken => {
    const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);
    try {
        const data = axios.get(configMgr.get('APIGW_HEARTBEAT_URL'), {
            headers: {
                Authorization: `Bearer ${bearerToken}`
            }
        });

        if (data !== null) {
            setUserBearerTokenTimeout((Date.now() + parseInt(configMgr.get('DAF_IDLE_TIMEOUT_SECS')) * 1000).toString());
            return bearerToken;
        } else {
            return null;
        }
    } catch (error) {
        throw new Error(`Failed to call Token Heartbeat with the token ${bearerToken}`);
    }
};

//The following we don't need to call. The token what is retrieved from  ModTokenV2 should be assigned for both  ModTokenV1 and  ModTokenV2
export const ModTokenV1 = async samlAccessToken => {
    try {
        return await axios.post(
            configMgr.get('MOD_SSO_AUTH_URL_V1'),
            qs.stringify({
                client_id: configMgr.get('MOD_SSO_AUTH_CLIENT_V1'),
                grant_type: 'urn:ietf:params:oauth:grant-type:saml2-bearer',
                scope: 'nab-mobile chartworks-image chartworks-html5',
                assertion: samlAccessToken
            })
        );
    } catch (error) {
        console.error('Retrieving MOD token V1 failed ', error);
    }
};

//Only use this ModTokenV2 to retrieve JBWere Mod Token.
export const ModTokenV2 = async samlAccessToken => {
    try {
        return await axios.post(
            configMgr.get('MOD_SSO_AUTH_URL_V2'),
            qs.stringify({
                client_id: configMgr.get('MOD_SSO_AUTH_CLIENT_V2'),
                grant_type: 'urn:ietf:params:oauth:grant-type:saml2-bearer',
                assertion: samlAccessToken
            })
        );
    } catch (error) {
        console.error('Retrieving MOD token V2 failed ', error);
    }
};

export const RevokeDafAuthToken = async () => {
    if (getSessionUserBearerToken()) {
        try {
            const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);
            await axios.post(configMgr.get('DAF_URL') + '/v1/idp/oauth/revoke', {
                token: getSessionUserBearerToken(),
                client_id: identityConfig.data['apps'][0]['appConfig']['clientId'],
                token_type_hint: 'access_token'
            });
        } catch (e) {}
        await removeToken(sessionStorage.bearerToken);
    }
};

export const Logout = async revokeDafAuthToken => {
    if (window.location.href !== document.baseURI && window.location.href !== document.baseURI + 'login') {
        try {
            if (revokeDafAuthToken) {
                await RevokeDafAuthToken();
            }
            //TODO: this should be in the context cache already - shouldn't require an extra get
            // const identityConfig = await axios.get(`portal/config/context-ma-client-identity-miniapp.json`);

            // await axios.post(configMgr.get('DAF_URL') + '/v1/idp/oauth/revoke', {
            //     token: getBearerToken(),
            //     client_id: identityConfig.data['apps'][0]['appConfig']['clientId'],
            //     token_type_hint: 'access_token'
            // });

            await removeToken(sessionStorage.bearerToken);

            const anonymousConfig = {
                tokenType: 'Bearer',
                tokenSource: 'DAF',
                tenant: 'jbwere',
                context: 'external' as AuthContext,
                environment: configMgr.get('DAF_ENVIRONMENT'),
                requestTokenOnLoad: true,
                scopes: 'idp:user',
                grantType: DafGrantType.Anonymous
            };

            //@ts-ignore
            getShellActions().updateApps({
                authConfig: anonymousConfig
            });
        } catch (error) {
            console.error('Error revoking token', error);
        }

        removeLocalStorageItemsByKeyPrefix('orderPad');
        removeLocalStorageItemsByKeyPrefix('portfolio');
        removeLocalStorageItemsByKeyPrefix('fundsTransfer');
        removeLocalStorageItemsByKeyPrefix('insights');
        setUserFirstName({
            nameInfo: '',
            userFirstName: ''
        });

        sessionStorage.clear();
        window.history.replaceState({}, document.title, document.baseURI + 'login');
        window.location.replace(document.baseURI + 'login');
    }
};
