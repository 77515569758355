import { IFlagStrategy, IUser, IFlagData, Dictionary } from '@nab/x-types';
import { LaunchDarklyClient, ILaunchDarklyFlagClient } from './LaunchDarklyClient';

const clientPool: Dictionary<ILaunchDarklyFlagClient> = {};

/**
 * [[include:packages/consumer/sdk-browser/src/FlagMgr/README.md]]
 */

export class LaunchDarklyFlagStrategy implements IFlagStrategy {
    public get isInitialised(): boolean {
        return !!clientPool[this.clientId];
    }

    private get client(): ILaunchDarklyFlagClient {
        return clientPool[this.clientId];
    }

    /**
     * constructor
     * @param clientId {string} sdk key.
     */
    constructor(public readonly clientId: string) {
        if (!clientId) {
            throw new Error('Invalid LaunchDarkly clientId.');
        }
    }

    /**
     * init the client object with flag values
     */
    public async initialise(user: IUser): Promise<void> {
        const client = clientPool[this.clientId] || new LaunchDarklyClient(this.clientId);
        await client.initialise(user);
        clientPool[this.clientId] = client;
    }

    /**
     * create a new client and initialise with the new user
     */
    public async reInitialise(user: IUser): Promise<void> {
        const client = new LaunchDarklyClient(this.clientId);
        await client.initialise(user);
        clientPool[this.clientId] = client;
    }

    public checkFlag<T = IFlagData>(flagName: string, defaultValue: T): T {
        return this.client ? this.client.checkFlag<T>(flagName, defaultValue) : defaultValue;
    }
}