import styled from 'styled-components';
import {
    THeadRow,
    Td,
    Th,
    Widget,
    Tab,
    Table,
    THead,
    SortableTh,
    Row,
    Link,
    Col,
    Container,
    Paragraph,
    H1,
    H3,
    Button,
    Modal,
    H5
} from '@nab/nui-react';
import jbWereBackground from '../../images/jbwereportal_login.jpg';

function priceChangeColor(priceChange) {
    if (priceChange < 0) {
        return ({ theme }) => theme.color.primary;
    } else if (priceChange > 0) {
        return ({ theme }) => theme?.color?.emerald;
    } else {
        return ({ theme }) => theme?.color?.black;
    }
}
function borderColor(priceChange) {
    if (priceChange === 0) {
        return 'transparent transparent transparent transparent';
    } else if (priceChange > 0) {
        return ({ theme }) => `transparent transparent ${theme?.color?.emerald} transparent`;
    } else {
        return ({ theme }) => `${theme.color.primary} transparent transparent  transparent`;
    }
}

function startEndPadding(start: boolean, end: boolean, button?: boolean) {
    if (start) {
        return ({ theme }) => `${theme.spaces.xs} 0 ${theme.spaces.xs} 0.75rem`;
    } else if (button && !end && !start) {
        return '0';
    } else if (button && start) {
        return '0 0 0.75rem 0';
    } else if (button && end) {
        return '0 0.75rem 0 0';
    } else if (end && !button) {
        return ({ theme }) => `${theme.spaces.xs} 0.75rem ${theme.spaces.xs} 0`;
    } else {
        return ({ theme }) => `${theme.spaces.xs}`;
    }
}

function tradeCodeColumnColor(isTradeCode: boolean) {
    if (isTradeCode) {
        return ({ theme }) => theme.color.primary;
    }
}

function tradeCodeColumnText(isTradeCode: boolean) {
    if (isTradeCode) {
        return 'underline';
    }
}

function arrowPosition(priceChange) {
    if (priceChange === 0) {
        return '0px';
    } else if (priceChange > 0) {
        return '-2px';
    } else {
        return '2px';
    }
}

function buySellColor(buySell) {
    if (buySell === 'BUY' || buySell === 'BUY *CO') return ({ theme }) => theme.color.emerald;
    else if (buySell === 'SELL' || buySell === 'SELL *CO') return ({ theme }) => theme.color.primary;
    else {
        return;
    }
}
export const StyledWidget = styled(Widget)`
    border: none;
    padding: 0;
    h2 {
        font-size: ${({ theme }) => theme.font.size.sm};
    }
`;

export const NoPrintSection = styled.div`
    padding: 0;
    margin: 0;
    @media print {
        display: none;
    }
`;

// Table

export const StyledTable = styled(Table)`
    padding: 0.1rem;
    margin: -0.1rem;
    font-size: ${({ theme }) => theme.font.size.xxs};
`;

const ScrollableTable = styled(StyledTable) <{ tableHeight?: number }>`
    padding: 0;
    height: ${props => (props.tableHeight ? props.tableHeight + 'px' : '400px')};
    overflow: scroll;
    thead {
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        th {
            background-color: white;
        }
    }
    @media print {
        height: auto;
        overflow: visible;
    }
`;

export const StyledTHead = styled(THead)`
    background-color: unset;
`;

export const StyledTFoot = styled.tfoot`
    background-color: ${({ theme }) => theme.color.grey96};
    position: sticky;
    inset-block-end: 0;
    bottom: 0;
`;

export const StyledTh = styled(Th) <{ widthPercent?: number; start?: boolean; end?: boolean }>`
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    width: ${props => (props.widthPercent ? props.widthPercent + '%' : '')};
    padding: ${props => startEndPadding(props.start, props.end)};
    white-space: nowrap;
`;

export const StyledSortableTh = styled(SortableTh) <{ widthPercent?: number; start?: boolean; end?: boolean }>`
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    width: ${props => (props.widthPercent ? props.widthPercent + '%' : '')};
    padding: ${props => startEndPadding(props.start, props.end)};
    white-space: nowrap;

    div:nth-child(2) {
        padding: 0.5rem 0;

        p {
            font-size: ${({ theme }) => theme.font.size.xxs};
            width: 100%;
            margin-left: 0;
            padding: 0;
        }
        span {
            margin-right: -0.6rem;
        }
    }
`;

export const StyledTd = styled(Td) <{
    widthPercent?: number;
    start: boolean;
    end: boolean;
    button: boolean;
    isTradeCode: boolean;
    textWrap: boolean;
}>`
    color: ${props => tradeCodeColumnColor(props.isTradeCode)};
    text-decoration: ${props => tradeCodeColumnText(props.isTradeCode)};
    white-space: ${props => (props.textWrap ? 'normal' : 'nowrap')};
    width: ${props => (props.widthPercent ? props.widthPercent + '%' : '')};
    padding: ${props => startEndPadding(props.start, props.end, props.button)};
`;

export const StyledFooterTd = styled(Td) <{ padding: string; start: boolean; end: boolean; alignTop: boolean }>`
    white-space: nowrap;
    padding: ${props => (props.padding ? props.padding : startEndPadding(props.start, props.end))};
    vertical-align: ${props => (props.alignTop ? 'top' : 'unset')};
`;

export const StyledThRow = styled(THeadRow)`
    z-index: 1;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey50} !important;
`;

export const StyledRow = styled(Row)`
    margin-top: 24px;
    margin-bottom: 24px;
`;
export const StyledDropdown = styled.div`
    svg {
        fill: ${({ theme }) => theme.color.black};
        color: ${({ theme }) => theme.color.black};
    }
`;

export const StyledCol = styled(Col)`
    margin-top: 24px;
`;

// export const StyledContainer = styled(Container)`
//     padding: ${props => startEndPadding(props.$start, props.$end)};
// `;

export const StyledIconMenu = styled.div`
    display: flex;
    align-items: center;
    float: right;
    button.green {
        color: ${({ theme }) => theme.color.emerald};
        svg {
            fill: ${({ theme }) => theme.color.emerald};
            color: ${({ theme }) => theme.color.emerald};
        }
    }

    button.red {
        color: ${({ theme }) => theme.color.primary};
        svg {
            fill: ${({ theme }) => theme.color.primary};
            color: ${({ theme }) => theme.color.primary};
        }
    }

    ul {
        max-width: 180px;
    }
`;

export const StyledIconMenuNoFloat = styled.div`
    @extend StyledIconMenu;
    float: none;
`;

export const SkeletonCellTextWrap = styled.div<{ cellHeight?: number }>`
    line-height: 1.5rem;
    height: ${props => (props.cellHeight ? props.cellHeight + 'px' : '24px')};
    display: flex;
    align-items: center;
`;

// Tabs
export const StyledTab = styled(Tab)`
    width: 100%;
    [data-component-id='TabItem'] {
        padding: 1rem 0 0 0 !important;
    }
    [data-component-id='TabNavigationItem'] {
        button {
            font-size: ${({ theme }) => theme.font.size.xs};
            font-family: 'DM Sans';
        }
    }
`;

// Formatting
export const StyledWhiteDiv = styled.div<{ width?: string; height?: string }>`
    background-color: ${({ theme }) => theme.color.white};
    border: none;
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '100%')};
`;
export const StyledGrayDiv = styled.div`
    background-color: ${({ theme }) => theme.color.grey96};
`;

export const StyledCenteredDiv = styled.div<{ height?: string }>`
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${props => (props.height ? props.height : '100%')};
`;
export const StyledParagraph = styled(Paragraph) <{ margin?: string; padding?: string }>`
    margin: ${props => (props.margin ? props.margin : '0')};
    padding:${props => (props.padding ? props.padding : '0')};}
`;
export const FlexContainerWidget = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    margin: 12px;
    padding: 0 15px;
    font-size: 15px;
    border-radius: 10px;
`;

export const EllipsisDiv = styled.div<{ width?: string; title?: string }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${props => (props.width ? props.width : '100%')};
`;

export const FlagDiv = styled.div<{ margin?: string }>`
    display: inline-block;
    max-width: 120px;
    margin: ${props => (props.margin ? props.margin : 0)};
    > img {
        height: auto;
        max-width: 100%;
        min-width: 100%;
        vertical-align: middle;
    }
`;
export const HorizontalRule = styled.hr`
    background-color: ${({ theme }) => theme.color.grey50};
    border: none;
    height: 0.05rem;
`;
// PriceChange and Arrow
export const ChangeValueDiv = styled.div<{ minWidth?: string }>`
    min-width: ${props => (props.minWidth ? props.minWidth : 'unset')};
    display: flex;
`;
export const ChangeDiv = styled.div<{ priceChange: any; justifyContentNormal?: boolean }>`
    color: ${props => priceChangeColor(props.priceChange)};
    display: flex;
    line-height: 1.75rem;
    align-items: center;
    justify-content: ${props => (props.justifyContentNormal ? 'normal' : 'flex-end')};
`;
export const BuySellOptionDiv = styled.span<{ buySell: string }>`
    color: ${props => buySellColor(props.buySell)};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
`;

export const ArrowDiv = styled.div<{ priceChange: number; size?: number }>`
    position: relative;
    top: ${props => arrowPosition(props.priceChange)};
    margin-right: 4px;
    width: 0;
    height: 0;
    border-width: ${props => (props.size ? props.size + 'px' : '5px')};
    border-style: solid;
    border-color: ${props => borderColor(props.priceChange)};
`;

const PanelButtons = styled.article`
    width: 100%;
    text-align: center;
    font-family: 'DM Sans';
    max-width: 800px;
`;

const Panel = styled.article`
    width: 100%;
    text-align: center;
    font-family: 'DM Sans';
    max-width: ${({ theme }) => theme.grid.container.maxWidth};
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ${({ theme }) => theme.spaces.xxlg} ${({ theme }) => theme.spaces.md};
`;

const Message = styled.section`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spaces.md};
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spaces.lg};
`;

const Links = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: ${({ theme }) => theme.spaces.md};
    justify-content: center;
    align-items: center;
`;

const StyledLabel = styled.label`
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey30};
`;

const StyledSnapshotLabel = styled.label`
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.grey30};
`;

const StyledSnapshotFunds = styled.div`
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: uppercase;
`;

const StyledFunds = styled.div`
    font-weight: normal;
    font-size: 1.6rem;
    text-transform: uppercase;
`;

const Spacer = styled.div<{ margin?: string; padding?: string }>`
    margin: ${props => (props.margin ? props.margin : '0')};
    padding: ${props => (props.padding ? props.padding : '0')};
`;

const BorderTop = styled.div`
    border-top: 1px ${({ theme }) => theme.color.grey50} solid;
`;

const SkeletonLoaderWrap = styled.div<{ padding?: string; width?: string }>`
    padding: ${props => (props.padding ? props.padding : 0)};
    width: ${props => (props.width ? props.width : '100%')};
    max-width: 100%;
`;

const StyledLink = styled(Link)`
    margin-left: 0.5rem;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin-right: 2rem;
`;

const StyledAccount = styled.div`
    margin-left: 33px;
    font-weight: ${({ theme }) => theme.font.weight.regular};
    font-size: ${({ theme }) => theme.font.size.xxs};
    font-family: 'DM Sans';
    line-height: 16px;
`;

const TotalText = styled.span`
    font-size: 18px;
    font-weight: 700;
`;

const VerticalAlignDiv = styled.div`
    display: block;
    align-items: center;
`;

const HeaderIcon = styled.div`
    min-width: 25px;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    flex: none;
    color: ${({ theme }) => theme.color.grey30};
`;

const HeaderDiv = styled.div`
    font-style: normal;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.sm};
    font-family: 'DM Sans';
    line-height: 24px;
    display: flex;
    svg {
        margin-right: 0.5rem;
    }
`;

export const StyledDiv = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;
    padding-bottom: 24px;
`;
export const TACMainContainer = styled(Modal)`
    gap: 40px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;

    margin-top: 80px;
    max-width: 904px;
    max-height: 728px;
    position: absolute;
    @media (min-width: 768px) and (max-width: 900px){
        max-width: 650px;
        margin-top: 60px;
    }
`;
export const TACContainer = styled.div`
    width: 824px;
    height: 520px;
    top: 40px;
    left: 40px;
    gap: 40px;
    opacity: 0px;

    overflow: auto;
    width: 100%;
    text-align: center;
    max-width: ${({ theme }) => theme.grid.container.maxWidth};
`;
export const TACContainerWithBtn = styled.div`
    width: 824px;
    height: 608px;

    width: 100%;
    text-align: center;
    max-width: ${({ theme }) => theme.grid.container.maxWidth};
`;

export const TACMainHeading = styled(H1)`
    //styleName: H1/light;
    font-family: Playfair Display;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: ${({ theme }) => theme.color.primary};
    padding-bottom: 32px;
    @media (max-width: 576px) {
        font-size: 32px;
        line-height: 40px;
    }
`;

export const TACH3 = styled(H3)`
    //styleName: H3/light;
    font-family: Playfair Display;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: ${({ theme }) => theme.color.primary};
    padding-bottom: 18px;
    @media (max-width: 576px) {
        font-size: 18px;
        line-height: 26px;
    }
`;
export const TACContent = styled.div`
    font-family: 'DM sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    span {
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        font-weight: ${({ theme }) => theme.font.weight.bold};
        display: inline-block;
        white-space: pre-wrap;
    }
`;
export const AcceptBtn = styled(Button)`
    float: right;
    margin-top: 40px;
    margin-bottom: 40px;
`;
export const TACWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 1;
    background: url(${jbWereBackground});
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
`;

export {
    StyledAccount,
    PanelButtons,
    HeaderIcon,
    Panel,
    FlexContainer,
    Message,
    Links,
    Spacer,
    BorderTop,
    StyledLabel,
    StyledSnapshotLabel,
    StyledFunds,
    StyledSnapshotFunds,
    StyledLink,
    TotalText,
    VerticalAlignDiv,
    HeaderDiv,
    ScrollableTable,
    SkeletonLoaderWrap
};
