import React, { useEffect, useMemo, useState } from 'react';
import { SpaProps, MiniAppInstance, DafGrantType, getShellActions, useFlagStrategy, useFlag, AuthContext } from '@nab/x-spa-react';
import { SpaWrapper, FormDiv, BorderDiv, MaintanenceNotification } from './LoginSpa.styles';
import { GlobalStyle, Notification, Paragraph, H1, H2, H3, H4, H5, Theme, Container, Row, Col } from '@nab/nui-react';
import { AppError, AppLoading } from '../common';
import { AppViewport } from '../AppViewport/AppViewport';
import LoginHeader from '../LoginHeader/LoginHeader';
import Footer from '../Footer/Footer';
import { configMgr } from '@nab/nab-x-sdk-browser';
import { LaunchDarklyFlagStrategy } from '../../utils/launchDarkly/LaunchDarklyFlagStrategy';
import Markdown from 'markdown-to-jsx';

/**
 * you can pass the following additional parameters in the LoginSpa:
 * context, initError, routeError, actions, appConfig, ...others
 */
export default function LoginSpa({ activeAppInstance }: SpaProps) {
    const getDefaultOverrides = () => ({
        disableParsingRawHTML: true,
        overrides: {
            h1: { component: H1, props: { variant: 'light' } },
            h2: { component: H2, props: { variant: 'light' } },
            h3: { component: H3, props: { variant: 'light' } },
            h4: { component: H4, props: { variant: 'light' } },
            h5: { component: H5, props: { variant: 'light' } }
        }
    });

    const defaultOverrides = useMemo(() => getDefaultOverrides(), []);

    const { updateApps } = getShellActions();
    useFlagStrategy(new LaunchDarklyFlagStrategy(configMgr.get('LAUNCHDARKLY_CLIENT_ID', '')));
    const maintainanceBanner = useFlag<boolean>('maintenance-banner', false, true);
    const maintainanceBannerText = useFlag('maintenance-banner-text', { env: '', message: '' });
    const loginState = useFlag<boolean>('login-state', false, true);
    const loginStateText = useFlag('login-state-text', { env: '', message: '' });
    const jbwClientPortalKnownIssue = useFlag<boolean>('known-issue', false, true);
    const jbwClientPortalKnownIssueText = useFlag('known-issue-text', { env: '', message: '' });
    const [bannerNotification, setBannerNotification] = useState({
        show: false,
        message: ''
    });
    useEffect(() => {
        if (maintainanceBanner) {
            setBannerNotification({
                show: maintainanceBannerText?.message !== null && maintainanceBannerText?.message !== undefined ? maintainanceBanner : false,
                message:
                    maintainanceBannerText?.message !== null && maintainanceBannerText?.message !== undefined
                        ? maintainanceBannerText?.message?.replace('\n', '')
                        : ''
            });
        } else if (!loginState) {
            setBannerNotification({
                show: loginStateText?.message !== null && loginStateText?.message !== undefined ? !loginState : false,
                message: loginStateText?.message !== null && loginStateText?.message !== undefined ? loginStateText?.message : ''
            });
        } else if (jbwClientPortalKnownIssue) {
            setBannerNotification({
                show:
                    jbwClientPortalKnownIssueText?.message !== null && jbwClientPortalKnownIssueText?.message !== undefined
                        ? jbwClientPortalKnownIssue
                        : false,
                message:
                    jbwClientPortalKnownIssueText?.message !== null && jbwClientPortalKnownIssueText?.message !== undefined
                        ? jbwClientPortalKnownIssueText?.message
                        : ''
            });
        }
    }, [maintainanceBanner, loginState, jbwClientPortalKnownIssue]);
    const [logoutError, setLogoutError] = useState('');
    if (localStorage.getItem('fundstransfer/logoutError')) {
        setLogoutError(localStorage.getItem('fundstransfer/logoutError'));
        localStorage.removeItem('fundstransfer/logoutError');
    }
    useEffect(() => {
        const anonymousConfig = {
            tokenType: 'Bearer',
            tokenSource: 'DAF',
            tenant: 'jbwere',
            context: 'external' as AuthContext,
            environment: configMgr.get('DAF_ENVIRONMENT'),
            requestTokenOnLoad: true,
            scopes: 'idp:user',
            grantType: DafGrantType.Anonymous
        };

        //@ts-ignore
        updateApps({
            authConfig: anonymousConfig
        });
    }, [updateApps]);

    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme theme="jbwere">
                <SpaWrapper>
                    <LoginHeader />
                    {useMemo(
                        () => (
                            <>
                                <AppViewport>
                                    <Container fluid noSideGutters>
                                        <Row>
                                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                                {bannerNotification.show && (
                                                    <MaintanenceNotification>
                                                        <Notification type="info" hasClose={true}>
                                                            <Markdown options={defaultOverrides}>
                                                                {bannerNotification.message?.replace('\n', '')}
                                                            </Markdown>
                                                        </Notification>
                                                    </MaintanenceNotification>
                                                )}
                                                {logoutError !== '' && (
                                                    <MaintanenceNotification>
                                                        <Notification type="error" hasClose={true}>
                                                            {logoutError}
                                                        </Notification>
                                                    </MaintanenceNotification>
                                                )}
                                                <FormDiv>
                                                    <BorderDiv>
                                                        {loginState && (
                                                            <MiniAppInstance
                                                                appInstance={activeAppInstance}
                                                                loadingSlot={AppLoading}
                                                                errorSlot={AppError}
                                                            />
                                                        )}
                                                    </BorderDiv>
                                                </FormDiv>
                                            </Col>
                                        </Row>
                                    </Container>
                                </AppViewport>
                            </>
                        ),
                        [activeAppInstance]
                    )}
                    <Footer />
                </SpaWrapper>
            </Theme>
        </>
    );
}
