import styled from 'styled-components';
import { Link, Paragraph } from '@nab/nui-react';
export const StyledHeaderNavSubItem = styled.div`
    .navbar_item {
        background-color: ${({ theme }) => theme.color.white};
        padding-top: 0;

        a {
            color: ${({ theme }) => theme.color.black};
            text-align: left;
        }

        &:hover {
            background-color: ${({ theme }) => theme.color.grey90};

            a {
                text-decoration: underline;
                color: ${({ theme }) => theme.color.black};
            }
        }

        &:focus-within,
        &:focus {
            font-weight: 400;
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.grey30} !important;
            outline-offset: -3px;
            outline: 3px solid ${({ theme }) => theme.color.grey50};

            a {
                text-decoration: none;
                color: ${({ theme }) => theme.color.white} !important;
            }
        }

        &.active,
        &.active:hover,
        &:active,
        &:active:hover {
            font-weight: 700;
            background-color: #001e55;

            a {
                color: ${({ theme }) => theme.color.white};
            }

            &:focus-within,
            &:focus {
                font-weight: 400;
                color: ${({ theme }) => theme.color.white};
                background-color: ${({ theme }) => theme.color.grey30};
                outline-offset: -3px;
                outline: 3px solid ${({ theme }) => theme.color.grey50};

                a {
                    color: ${({ theme }) => theme.color.white};
                }
            }
        }
    }
`;

export const ToolsStyledHeaderNavSubItem = styled.div`
    .navbar_item {
        padding-top: 0;
        color: ${({ theme }) => theme.color.black};
        text-align: left;
    }
`;

export const StyledHeaderNavItem = styled.li`
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.grey96};
    color: ${({ theme }) => theme.color.black};
    display: block;
    position: relative;
    align-items: center;
    padding-top: 2px;
    transition-duration: 0.2s;

    &.navbar_header-item {
        border-bottom: 4px solid ${({ theme }) => theme.color.grey96};

        &:focus-within,
        &:focus {
            border-bottom: 4px solid ${({ theme }) => theme.color.grey30};

            & .navbar_header-link {
                text-decoration: underline;
            }
        }

        &.active,
        &.active:hover,
        &.active:focus-within,
        &.active:focus {
            border-bottom: 4px solid #001e55;
        }

        &:hover {
            border-bottom: 4px solid ${({ theme }) => theme.color.grey30};
            transition-duration: 0.2s;
            & .navbar_header-link {
                text-decoration: none;
            }
        }
    }

    a {
        display: inline-flex;
        align-items: center;
        height: 100%;
        width: 100%;
        color: ${({ theme }) => theme.color.primary};
        text-align: left;
        padding: 1rem;
        position: relative;
        text-decoration: none;
        transition: 0.2s;
        cursor: pointer;
        white-space: nowrap;
        outline-style: none;
    }

    .icon-wrap {
        line-height: 1.5rem;
        margin-top: 5px;
        margin-left: 7px;
    }

    .icon-wrap svg {
        transition-duration: 0.2s;
    }

    &:focus-within,
    &:focus {
        .icon-wrap svg {
            transform: scaleY(-1);
        }

        .navbar_sub-menu {
            display: block;
            padding: 1rem;
            position: absolute;
            width: 100%;

            &-panel {
                margin-top: 8px;
                border-radius: 0.5rem;
                border: 1px solid ${({ theme }) => theme.color.black};
                background-color: ${({ theme }) => theme.color.white};
                padding: 1.5rem;
                width: fit-content;
                box-shadow: 0 3px 7px ${({ theme }) => theme.color.grey70};
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
            }
        }
    }

    .navbar_sub-menu {
        display: none;
        width: 1rem;
        height: auto;
        padding: 0 !important;
    }
`;

export const DesktopMenu = styled.nav`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 60px;

    align-items: center;
    background-color: ${({ theme }) => theme.color.grey96};
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.xs};

    ul {
        list-style-type: none;
    }

    .navbar {
        color: ${({ theme }) => theme.color.black};
        margin-right: auto;
        height: 100%;
        display: flex;
        flex-flow: row;
        z-index: 10;
        padding-left: 0;

        &_link {
            &--home {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .navbar_enditem {
        color: ${({ theme }) => theme.color.black};
        margin-left: auto;
        height: 100%;
        display: flex;
        flex-flow: row;
        z-index: 10;
        padding-left: 0;

        &_link {
            &--home {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;

export const FormDiv = styled.div`
    background-color: ${({ theme }) => theme.color.white};
    position: relative;
    width: 100%;
    justify-content: space-evenly;

    @media (min-width: 840px) {
        display: flex;
        min-height: 55px;
    }
`;

export const BorderDiv = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.color.grey96};
    display: block;
`;

export const NavContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey90};
    position: relative;
    align-items: center;
    padding: 0 2rem;
    justify-content: center;
    display: flex;
    @media print {
        display: none;
    }
`;

export const NavLayout = styled.div`
    max-width: 1248px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
`;

export const StyledHr = styled.hr`
    padding: 0;
    margin: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.color.grey90};
    border: none;
`;

export const SkeletonWidth = styled.div`
    width: 220px;
    padding: 0.4rem;
`;

export const WelcomeTitle = styled.span`
    white-space: nowrap;
    justify-content: end;
    display: flex;
    @media (max-width: 1024px) {
        color: #001e55;
        padding-top: 1rem;
    }
`;

export const WelcomeSubTitle = styled.span`
    margin-top: -2px;
    font-size: 14px;
    text-decoration: none;
`;

export const WelcomeErrorIcon = styled.span`
    margin-right: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
`;

export const HeaderWelcomeTemplate = styled.div`
    display: inline-flex;
    text-decoration: none;
    align-content: center;
    text-align: right;
    align-items: center;
    padding-left: 1.25rem;
    color: #ffffff;
    font-size: 16px;
`;
export const DisabledMenuItems = styled.span`
    text-decoration: none;
    color: ${({ theme }) => theme.color.grey50};
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: 1024px) {
        white-space: normal;
    }
`;
