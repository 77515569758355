import { CheckFlagFn, IUser } from '@nab/x-types';
import * as LD from 'launchdarkly-js-client-sdk';
import { camelCase } from 'lodash';
import { log } from '../log/log';

export interface ILaunchDarklyFlagClient {
    checkFlag: CheckFlagFn;
    rawClient(): any;
    initialise(user: IUser): Promise<void>;
}

/**
 * LaunchDarklyClient
 * This represents and instance of the LaunchDarkly sdk client
 */
export class LaunchDarklyClient implements ILaunchDarklyFlagClient {
    private ldClientObj: LD.LDClient;

    private flagDataObj: LD.LDFlagSet;

    private defaults: {};

    private initPromise: Promise<void>;

    private isInitialised: boolean = false;

    /**
     * @param {clientId} string The apiKey used to initialise the LaunchDarkly client.
     */
    constructor(public readonly clientId: string) {
        if (!clientId) {
            log.error('Missing LaunchDarkly clientId.');
        }
    }

    public initialise(user: IUser): Promise<void> {
        if (!this.initPromise) {
            this.initPromise = this.doInit(user);
        }
        return this.initPromise;
    }

    private async doInit(user: IUser): Promise<void> {
        const ldUser: LD.LDUser = {
            key: user.idHash,
            anonymous: !user.authenticated,
            custom: {
                ...user.details,
                org: user.org,
                type: user.type
            }
        };

        this.ldClientObj = LD.initialize(this.clientId, ldUser, { sendEventsOnlyForVariation: true });

        try {
            await this.ldClientObj.waitForInitialization();
            this.isInitialised = true;
            this.ldClientObj.on('change', this.combineFlags);
            this.combineFlags();
        } catch (error) {
            log.error(error);
        }
    }

    private formatFlags = (flags) => Object.entries(flags).reduce((prev, [key, value]) => ({ ...prev, [camelCase(key)]: value }), {});

    private combineFlags = () => {
        this.flagDataObj = { ...this.defaults, ...this.formatFlags(this.ldClientObj.allFlags()) };
    };

    public flagData(): LD.LDFlagSet {
        return this.flagDataObj;
    }

    public rawClient(): LD.LDClient {
        return this.ldClientObj;
    }

    public checkFlag<T = LD.LDFlagValue>(flagName: string, defaultValue: T): T {
        const result: T = this.isInitialised ? this.ldClientObj.variation(flagName, defaultValue) : defaultValue;
        return result;
    }
}