import React from 'react';
import { Spinner } from '@nab/nui-react';
import { LoadingSlotProps } from '@nab/x-spa-react/module/components/MiniAppInstance';
import styled from 'styled-components';
import { rem } from 'polished';

export const SpinnerContainer = styled.div`
    padding: ${rem('35px')} ${rem('35px')};
    border-radius: ${({ theme }) => theme.radius};
    align-content: center;
`;

const AppLoading: React.FC<LoadingSlotProps> = () => {
  return (
      <SpinnerContainer>
          <Spinner data-testid="loading" size="lg" message="Loading..." />
      </SpinnerContainer>
  );
};

export default AppLoading;
