import React from 'react';
import { DisabledSecondaryListItem, IconHeight, SecondaryListItem, StyledHr } from './SideMenuNavList.styles';
import { Icon } from '@nab/nui-react';
import { getShellActions } from '@nab/x-runtime';
import { DisabledMenuItems } from '../SubHeaderNav/SubHeaderNav.styles';
import { getIsClient } from '../../../utils/sessionManager';

interface SubMenuItem {
    index: any;
    route: any;
    subRoute: any;
    baseRoute: any;
    label: any;
    icon: any;
    actions: any;
    setIsCollapsed: any;
}

export function SideMenuSecondaryListItem({ index, route, subRoute, baseRoute, label, icon, actions, setIsCollapsed }: SubMenuItem) {
    const onNavSubItemClick = e => {
        e.preventDefault();
        getShellActions().navigate(baseRoute + route);
        setIsCollapsed(true);
    };
    let isClient = null;
    if (isClient === null) {
        isClient = getIsClient();
    }
   
    return (
        <>
            {isClient == 'false' && (label == 'Make a transfer or payment' || label == 'Manage payees & payments') ? (
                <div data-testid="side-menu-secondary-list-item">
                    {index === 0 && <StyledHr data-testid="styledHr" />}
                    <DisabledSecondaryListItem data-testid="secondaryListItem" className={`${route === subRoute ? 'active' : ''}`}>
                        <DisabledMenuItems>
                            <p>{label}</p>
                        </DisabledMenuItems>
                        {icon && (
                            <span>
                                &nbsp;
                                <Icon data-testid="icon" name={icon} size="sm" />
                            </span>
                        )}
                    </DisabledSecondaryListItem>
                    <StyledHr />
                </div>
            ) : (
                <div data-testid="side-menu-secondary-list-item" onClick={onNavSubItemClick}>
                    {index === 0 && <StyledHr data-testid="styledHr" />}
                    <SecondaryListItem data-testid="secondaryListItem" className={`${route === subRoute ? 'active' : ''}`}>
                        <a href="#">
                            <span data-testid="label">{label}</span>
                            {icon && (
                                <IconHeight>
                                    &nbsp;
                                    <Icon data-testid="icon" name={icon} size="sm" />
                                </IconHeight>
                            )}
                        </a>
                    </SecondaryListItem>
                    <StyledHr />
                </div>
            )}
        </>
    );
}

export default SideMenuSecondaryListItem;
