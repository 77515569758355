import { Container } from '@nab/nui-react';
import React from 'react';
import {
    FormDiv,
    StyledLInk,
    FooterContainer,
    StyledCallIcon,
    FooterLinkContactUs,
    StyledLinkIcon,
    FooterContents,
    FooterText,
    CopyrightsText,
    BorderDiv,
    StyledBr
} from './Footer.styles';

export function Footer() {
    return (
        <FormDiv data-testid="form-div">
            <BorderDiv data-testid="border-div">
                <Container fluid noSideGutters>
                    <FooterContainer data-testid="footer-container">
                        <FooterContents>
                            <FooterText>
                                <FooterLinkContactUs variant="standard" href="https://www.jbwere.com.au/contact" target="_blank">
                                    Contact
                                    <StyledBr /> us
                                    <StyledCallIcon data-testid="styled-call-icon" />
                                </FooterLinkContactUs>
                            </FooterText>
                            <FooterText>
                                <StyledLInk variant="standard" href="https://www.jbwere.com.au/-/privacy" target="_blank">
                                    Privacy
                                    <StyledBr /> Policy
                                    <StyledLinkIcon data-testid="styled-call-icon" />
                                </StyledLInk>
                            </FooterText>
                            <FooterText>
                                <StyledLInk variant="standard" href="https://www.jbwere.com.au/-/terms-conditions" target="_blank">
                                    Terms and
                                    <StyledBr /> conditions
                                    <StyledLinkIcon data-testid="styled-call-icon" />
                                </StyledLInk>
                            </FooterText>
                            <FooterText>
                                <StyledLInk variant="standard" href="https://www.jbwere.com.au/resources/financial-services-guides" target="_blank">
                                    Financial Services
                                    <StyledBr /> Guide
                                    <StyledLinkIcon data-testid="styled-call-icon" />
                                </StyledLInk>
                            </FooterText>
                        </FooterContents>
                        <CopyrightsText> © JBWere Limited, AFSL and ACL 341162</CopyrightsText>
                    </FooterContainer>
                </Container>
            </BorderDiv>
        </FormDiv>
    );
}

export default Footer;
