import React from 'react';
import { StyledHeaderNavSubItem, StyledHr, ToolsStyledHeaderNavSubItem, DisabledMenuItems } from './SubHeaderNav.styles';
import { Icon } from '@nab/nui-react';
import { getShellActions } from '@nab/x-runtime';
import { getIsClient } from '../../../utils/sessionManager';
interface SubMenuItem {
    key: any;
    index: any;
    route: any;
    subRoute: any;
    baseRoute: any;
    label: any;
    icon: any;
    item: any;
}

export function NavSubItem({ index, route, subRoute, baseRoute, label, icon, item }: SubMenuItem) {
    let isClient = null;
    if (isClient === null) {
        isClient = getIsClient();
    }

    return (
        <>
            {isClient == 'false' && item.label == 'Transfer & Pay' ? (
                <ToolsStyledHeaderNavSubItem data-testid="styled-header-nav-sub-item">
                    {index === 0 && <StyledHr data-testid="styled-hr" />}
                    <li className={`navbar_item navbar_sub-menu-item ${route === subRoute ? 'active' : ''}`}>
                        <DisabledMenuItems>
                            <p>{label}</p>
                        </DisabledMenuItems>
                        {icon && (
                            <span>
                                &nbsp;
                                <Icon data-testid="icon" name={icon} size="sm" />
                            </span>
                        )}
                    </li>
                    <StyledHr />
                </ToolsStyledHeaderNavSubItem>
            ) : (
                <StyledHeaderNavSubItem data-testid="styled-header-nav-sub-item">
                    {index === 0 && <StyledHr data-testid="styled-hr" />}
                    {localStorage.getItem('route') === route ? (
                        <li className={`navbar_item navbar_sub-menu-item ${localStorage.getItem('route') === route ? 'active' : ''}`}>
                            <a data-testid="nav-link" className="navbar_link navbar_sub-menu-link" href={baseRoute + route}>
                                <span className="navbar_sub-menu-label">
                                    {label} {localStorage.removeItem('route')}
                                </span>
                                {icon && (
                                    <span>
                                        &nbsp;
                                        <Icon data-testid="icon" name={icon} size="sm" />
                                    </span>
                                )}
                            </a>
                        </li>
                    ) : (
                        <li className={`navbar_item navbar_sub-menu-item ${route === subRoute ? 'active' : ''}`}>
                            <a data-testid="nav-link" className="navbar_link navbar_sub-menu-link" href={baseRoute + route}>
                                <span className="navbar_sub-menu-label">{label}</span>
                                {icon && (
                                    <span>
                                        &nbsp;
                                        <Icon data-testid="icon" name={icon} size="sm" />
                                    </span>
                                )}
                            </a>
                        </li>
                    )}
                    <StyledHr />
                </StyledHeaderNavSubItem>
            )}
        </>
    );
}

export default NavSubItem;
