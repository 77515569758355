import styled from 'styled-components';
import { ProfileIcon, Button } from '@nab/nui-react';

export const FormDiv = styled.div`
    background-color: #001e55;
    position: relative;
    width: 100%;
    justify-content: space-evenly;
    @media (min-width: 840px) {
        display: flex;
        min-height: 55px;
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    @media print {
        display: none;
    }
`;

export const HeaderLayout = styled.div`
    max-width: 1252px;
    width: 100%;
`;

export const BorderDiv = styled.div`
    padding: 0.5rem 2rem;
    width: 100%;
`;

export const LogoContainer = styled.img`
    display: flex;
    padding: 4px 11px;
    margin-top: 4px;
    width: 150px;
    height: 45px;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
`;

export const HeaderContentFlex = styled.div`
    display: flex;
    justifycontent: space-between;
`;

export const ButtonWrap = styled.div`
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: center;
    justify-content: end;
    padding-right: 30px;
`;
export const ButtonLink = styled.div`
    display: flex;
    align-items: center;
    &:active: {
        border: 1px solid ${({ theme }) => theme.color.grey30};
    }
    .icon-wrap {
          line-height: 1.5rem;
          margin-top: 5px;
          margin-left: 7px;
          color:#ffffff;
        }
    .icon-wrap svg {
          transition-duration: .2s;
          color:#ffffff;
        }
    }
`;

export const ButtonProfile = styled(Button)`
    display: flex;
    padding: 12px 24px;
    &:active: {
        border: 1px solid ${({ theme }) => theme.color.grey30};
    }
    color: ${({ theme }) => theme.color.white};
    &:hover {
        color: ${({ theme }) => theme.color.white};
    }
`;

export const ProfileIconCustom = styled(ProfileIcon)`
    color: ${({ theme }) => theme.color.white};
    align-items: center;
`;

export const ButtonLogout = styled(Button)`
    display: flex;
    padding: 12px 24px;
`;

export const HeaderTickerWrap = styled.div`
    width: 295px;
    display: inline-flex;
    align-items: center;
    text-align: left;
`;

export const HeaderTickers = styled.div`
    display: none;
    color: white;
    // display: inline-flex;
    align-items: center;
    width: 100%;
`;
