import React, { useState } from 'react';
import { DesktopMenu, FormDiv, BorderDiv, NavContainer, NavLayout, StyledHeaderNavItem } from './SubHeaderNav.styles';
import { Container, HomeIcon, ButtonIcon } from '@nab/nui-react';
import { NavItem } from './NavItem';
import { getIsClient } from '../../../utils/sessionManager';
import { getShellActions } from '@nab/x-runtime';

interface SubMenu {
    actions: any;
    activeRoute: any;
    subRoute: any;
    items: any;
    baseRoute: any;
    loading: boolean;
    error: boolean;
    userLoginDetails: any;
}

export function SubHeaderNav({ actions, activeRoute, subRoute, items, baseRoute, loading, error, userLoginDetails }: SubMenu) {
    const onClientHomeClick = e => {
        getShellActions().navigate('/portal/portfolio/portfolio-summary');
    };
    const onAdvisorHomeClick = e => {
        getShellActions().navigate('/portal/profile-help/client-list');
    };

    const [currentActiveIndex, setCurrentActiveIndex] = useState(-1);

    const filteredMenu = items.filter(item => {
        return item.label != 'Profile & Help';
    });
    const filteredRightMenu = items.filter(item => {
        return item.label === 'Profile & Help';
    });
    const isClient = getIsClient();

    return (
        <FormDiv data-testid="form-div">
            <BorderDiv>
                <Container fluid>
                    <NavContainer>
                        <NavLayout>
                            <DesktopMenu>
                                <ul className="navbar">
                                    <StyledHeaderNavItem className="navbar_item" data-testid="styled-header-nav-item">
                                        {isClient === 'true' ? (
                                            <ButtonIcon label="HomeIcon" icon={HomeIcon} color="black" onClick={onClientHomeClick} />
                                        ) : (
                                            <ButtonIcon label="HomeIcon" icon={HomeIcon} color="black" onClick={onAdvisorHomeClick} />
                                        )}
                                    </StyledHeaderNavItem>
                                    {filteredMenu.map((item, index) => (
                                        <NavItem
                                            key={item.route}
                                            index={index}
                                            activeRoute={activeRoute}
                                            subRoute={subRoute}
                                            baseRoute={baseRoute}
                                            item={item}
                                            actions={actions}
                                            currentActiveIndex={currentActiveIndex}
                                            setNavItemIndex={setCurrentActiveIndex}
                                            isClient={isClient}
                                        />
                                    ))}
                                </ul>
                                <ul class="navbar_enditem">
                                    {filteredRightMenu.map((item, index) => (
                                        <NavItem
                                            key={item.route}
                                            index={index}
                                            activeRoute={activeRoute}
                                            subRoute={subRoute}
                                            baseRoute={baseRoute}
                                            item={item}
                                            actions={actions}
                                            currentActiveIndex={currentActiveIndex}
                                            setNavItemIndex={setCurrentActiveIndex}
                                            isClient={isClient}
                                        />
                                    ))}
                                </ul>
                            </DesktopMenu>
                        </NavLayout>
                    </NavContainer>
                </Container>
            </BorderDiv>
        </FormDiv>
    );
}

export default SubHeaderNav;
