import styled from 'styled-components';
import {
    Modal
} from '@nab/nui-react';
export const SessionTimeOutModal = styled(Modal)`
    [data-name='container'] {
        button{
            margin-top: 70px;
        }
    }  
`;

