import React from 'react';
import {
  FormDiv,
  BorderDiv,
  HeaderContainer,
  HeaderLayout,
  LogoContainer,
  HeaderContentFlex, ButtonWrap, ButtonLogout
} from './Header.styles'
import { Container, Row, Col, LogOutIcon } from '@nab/nui-react';
import { Logout } from '../helper';
import jbWereLogo from '../../images/jbWere_Logo.svg';
import SubHeaderAccountWelcome from "./SubHeaderNav/SubHeaderWelcome";

interface HeaderInterface {
  actions: any,
  loading: boolean,
  error: boolean,
  userLoginDetails: any
}

export function Header({ actions, loading,
  error,
  userLoginDetails }) {

  return (
          <FormDiv data-testid="form-div">
              <BorderDiv data-testid="border-div">
                  <Container fluid noSideGutters>
                      <HeaderContainer data-testid="header-container">
                          <HeaderLayout>
                              <Row>
                                  <Col xl="2" lg="2" md="2" sm="2" xs="3">
                                      <LogoContainer src={jbWereLogo} />
                                  </Col>
                                  <Col xl="10" lg="10" md="10" sm="10" xs="9" style={{ textAlign: 'right' }}>
                                      <HeaderContentFlex>
                                          <ButtonWrap> 
                                              <SubHeaderAccountWelcome loading={loading} error={error} userLoginDetails={userLoginDetails} />
                                              <ButtonLogout
                                                  variant="secondary"
                                                  icon={LogOutIcon}
                                                  iconToRight={true}
                                                  label="Log out"
                                                  onClick={Logout}
                                              />
                                          </ButtonWrap>
                                      </HeaderContentFlex>
                                  </Col>
                              </Row>
                          </HeaderLayout>
                      </HeaderContainer>
                  </Container>
              </BorderDiv>
          </FormDiv>
  );
}

export default Header;
