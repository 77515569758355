export const getArbirtaryKeyFromLS = ({ key }) => {
    return localStorage.getItem(key);
};

export const getLastLogin = () => {
    return localStorage.getItem('lastLogin');
}

export const getUserFirstName = () => {
    return JSON.parse(localStorage.getItem('userGivenName'));
}

export const getLastLoginByUser = (username) => {
    return localStorage.getItem('lastLogin' + username);
};

export const getUserBearerToken = () => {
    return localStorage.getItem('userBearerToken');
}

export const getSessionUserBearerToken = () => {
    return sessionStorage.getItem('bearerToken');
}

export const getUserBearerTokenExpiry = () => {
    return localStorage.getItem('userBearerTokenExpiry');
};

export const getUserBearerTokenTimeout = () => {
    return localStorage.getItem('userBearerTokenTimeout');
}

export const getUserModAlertProfileCreated = () => {
    return localStorage.getItem('userModAlertProfileCreated');
}

export const getUserModTokenV1 = () => {
    return localStorage.getItem('userModTokenV1');
}

export const getUserModTokenExpiryV1 = () => {
    return localStorage.getItem('userModTokenExpiryV1');
}

export const getUserModTokenV2 = ()  => {
    return localStorage.getItem('userModTokenV2');
}

export const getUserModTokenExpiryV2 = () => {
    return localStorage.getItem('userModTokenExpiryV2');
}

export const setArbirtaryKeyInLS = ({ key, value }) => {
    localStorage.setItem(key, value);
};

export const removeLocalStorageItemsByKeyPrefix = (prefix) => {
    Object.keys(localStorage)
        .filter(x => x.startsWith(prefix))
        .forEach(x => localStorage.removeItem(x))
};

export const setLastLogin = (lastLogin) => {
    localStorage.setItem('lastLogin', lastLogin ?? Date.now().toString());
};

export const setUserFirstName = (userName) => {
    let userObjArray = [];

    if(getUserFirstName()){
        getUserFirstName().map(item=>{
            userObjArray.push(item);
        })
    }

    userObjArray.push(userName)

    var originalArray = userObjArray.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.nameInfo === value.nameInfo)
      );
    localStorage.setItem('userGivenName', JSON.stringify(originalArray));
};

export const setLastLoginByUser = (username) => {
    localStorage.setItem('lastLogin' + username, Date.now().toString());
};

export const setUserBearerToken = (bearerToken): void => {
    localStorage.setItem('userBearerToken', bearerToken);
};

export const setUserBearerTokenExpiry = (bearerTokenExpiry): void => {
    localStorage.setItem('userBearerTokenExpiry', bearerTokenExpiry);
};

export const setUserBearerTokenTimeout = (bearerTokenTimeout): void => {
    localStorage.setItem('userBearerTokenTimeout', bearerTokenTimeout);
};

export const setUserIdentityInfo = (identityInfo): void => {
    localStorage.setItem('userIdentityInfo', JSON.stringify(identityInfo));
};

export const setUserModAlertProfileCreated = () => {
    localStorage.setItem('userModAlertProfileCreated', 'true');
}

export const setUserModTokenV1 = (modTokenV1) => {
    localStorage.setItem('userModTokenV1', modTokenV1);
}

export const setUserModTokenExpiryV1 = (modTokenExpiryV1) => {
    localStorage.setItem('userModTokenExpiryV1', modTokenExpiryV1);
}

export const setUserModTokenV2 = (modTokenV2) => {
    localStorage.setItem('userModTokenV2', modTokenV2);
}

export const setUserModTokenExpiryV2 = (modTokenExpiryV2) => {
    localStorage.setItem('userModTokenExpiryV2', modTokenExpiryV2);
}
